import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { collection, doc, onSnapshot, setDoc,query,where, updateDoc, getDoc, serverTimestamp, deleteDoc, orderBy } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import DashboardNav from './dashboardNav';


export default function Notification() {
    const [pop,setpop] =  useState(false)
    const [notificationPop,setnotificationPop] =  useState(false)
    const [spinner,setspinner] =  useState(true)
    const navigate = useNavigate()
    const [user,setuser] =  useState([])
    const [notification,setnotification] =  useState([])
    const [uid,setuid] =  useState('')
   
      function handleLogOut(){
      }

      function mobileNavPop(){
        setpop(true)
    
    
      
    
    }

    
  // Profile Photo Click Handler
  const popProfile = () =>{
    if(pop == true){
      setpop(false)
    setnotificationPop(false)


    }
    else{
    setpop(true)
    setnotificationPop(false)



    }
}
const convertTimestampToDate = (timestamp) => {
  const date = timestamp.toDate();
  const currentDate = new Date();
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  let relativeTime = '';

  if (timeDifferenceInSeconds < secondsInMinute) {
    relativeTime = `${timeDifferenceInSeconds}s ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    relativeTime = `${minutes}m ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    relativeTime = `${hours}h ago`;
  } else if (timeDifferenceInSeconds < secondsInWeek) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    relativeTime = `${days}d ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
    relativeTime = `${weeks}w ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    relativeTime = `${months}mo ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    relativeTime = `${years}y ago`;
  }

  return relativeTime;
};
const popNotification = () =>{
  if(notificationPop == true){
    setnotificationPop(false)
    setpop(false)
  }
  else{
  setnotificationPop(true)
  setpop(false)



  }
}
   function removeNotification(id){
    const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);

    setDoc(notificationRef,{
      seen:true
    },{merge:true})
    .then(() => {
      
      setnotification(prevArray => prevArray.filter(item => item.id !== id));
    })
    .catch((error) => {
    });


   }
  let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)
    
    // Mobile Nav Pop minimize
    function mobileNavPopMinimize(){
    setpop(false)
    
    
    
    }
    

      useEffect(()=>{
       
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {

          
              const uid = user.uid;
            //  setcred(uid)
          setuid(uid);
             
          const userRef = doc(firestore,"TotalUsers",uid);
  const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");
  const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

  onSnapshot(userRef,snapshot=>{
   
    let main =[]



      main.push({...snapshot.data(),id:snapshot.id})
      
  setuser(main)

    
    
  

  })
  onSnapshot(notificationRef,snapshot=>{
    let main =[]



    snapshot.docs.forEach(doc=>{
      main.push({...doc.data(),id:doc.id})
     
    setnotification(main)
    })

    
    
  

  })
        
  
      
     
        
        
                  
        
               
        
                      
            
        
              
        
             
              
              // ...
            } else {
              console.log("logged out")
              
            }
          });

          return () => {
            unsubscribe();
          };

    },[])

  
    function desktopSpinnerHandler(){
      setspinner(false)
     }
function handleLogOut(){
signOut(auth).then(
navigate('/')
)
}
    

  return (
    user.length != 0 ?
        user.map(i=>{
            const {fullName,photo,plan} = i
            return(
                <div>
                      {
  spinner == true &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span
>
</div>

  </div>
 }
                <div id='desktop'>
           
      <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
      
            <div>
            <ul className='flex justify-start border-b'>
              <div className=' flex justify-evenly   '>
                    <Link to='/dashboard'>
                 <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
      
                    </Link>
      <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
      
                    </Link>
      <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
      
                    </Link>
                    <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
      
                    </Link>
      
                    <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
      <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
      
                    </Link>
      
                 </div>
                
               
      
      
      
      
              </ul>
            </div>
            <p className='anton flex items-center text-gray-900 mt-8 mx-auto' style={{fontSize:"35px",width:'80%'}}>Notifications</p>
            <div className="mt-12  border shadow rounded-md mx-auto" style={{width:'80%'}}>
            {
              notification.map(i=>{
                const {notificationTitle,declinedReason,seen,time} = i
                return(
                  <div className='border-b border-t w-full flex justify-between py-8 px-4 items-center'>
                    <div>
            <p className='fjalla  text-gray-900 ' style={{fontSize:"18px"}}>{notificationTitle}</p>
            <p className='borel text-gray-500 mt-1'>{declinedReason}</p>

                    </div>

                    <p className='fjalla'>{convertTimestampToDate(time)}</p>

                  </div>
                )
              })
            }
            </div>
                </div>
                <div id='tablet'>
                <nav className='flex justify-between navTablet w-full  'style={{padding:'5vw 1vw 3vw 2vw'}}>
    
    <Link to="/dashboard"   className='' style={{width:'15%'}}>
              <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                </Link>
    
      <ul className='flex items-center justify-end' style={{width:'70%'}}>
    {
    plan == 'Free' &&
    <Link to='/pricing'>
    
    <p className='text-blue-800  hover:underline Mooli cursor-pointer' style={{fontSize:'15px',marginRight:'2vw'}}>Upgrade Plan</p>
    </Link>
    
    
    
    }
    <div  className="dropdown   mr-5 ">
      
      <div className='notibell relative mt-2'>
     {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
    <div>
      <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
        <button    style={{color:"black"}}className=" inline-block relative">
    <svg style={{width:'25px'}} xmlns="http://www.w3.org/2000/svg" className=" text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
    </svg>
    <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-green-400 bg-green-600"></span>
    </button>
      </div>
      {
        notificationPop == true &&
          <div id='notification' className=" menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
        {/* top */}
        <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
          <h1 className='py-3 Mooli'>Notifications</h1>
          <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
            {/* <strong>{notification.length}</strong> */}
            {notification && notification.length}
          </div>
        </div>
        <hr />
        {/* end top */}
        {/* body */}
        {/* item */}
      
        {/* end item */}
        {/* item */}
    
        {/* end body */}
        {/* bottom */}
     
    
        {
          notification && notification.length != 0 ?
          notification.slice(0,3).map(i=>{
            const {notificationTitle,declinedReason,time,id} = i
            return(
              <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>
    
    <div className='relative  flex items-center justify-between  '>
    <svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
    </svg>
    <p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
    {notificationTitle} <br />
    <span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
            </p>
    
            <p className='fjalla'>
              {convertTimestampToDate(time)}
    
            </p>
          
    </div>
    <div className=" px-4 py-2 mt-2 fjalla">
          <Link
            to="/notification"
            className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
          >
            view all
          </Link>
        
        </div>
    </div>
    
            )
          }) :
          <div> 
          <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"3.5vw"}}>
      No notifications
    
          </h1>
          <p className='text-gray-400 text-center pb-4 px-3'style={{fontSize:"2vw"}}>
    We'll let you know when deadlines are approaching, or there is a course update
    
          </p>
      </div> 
        }
    
    
    
    
     
        {/* end bottom */}
      </div>
      }
      </div>
      </div>
     
    
    </div>
    <div className="flex  items-center">
    {/* user */}
    
    </div>
    <svg onClick={mobileNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"40px"}} fill="currentColor" className="text-gray-900 cursor-pointer  bi bi-list" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
    </svg> 
    
    
    </ul>
    </nav>
    {
    pop == true &&
    <div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
    <div className='flex justify-between px-7 py-5'>
    <div className='flex justify-start' style={{width:'33%'}}>
    
    <svg  onClick={mobileNavPopMinimize} xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="currentColor" class="cursor-pointer bi bi-arrow-left-short text-gray-800" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
    </div>
    
    <div className='relative' style={{width:'15%'}}>
    <Link to="/dashboard"  className='' style={{width:'10%'}}>
              <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                </Link>
                </div>
                <div className='flex justify-end' style={{width:'33%'}}>
    
                <Link to='/mycourse'>
      <button type='submit' style={{backgroundColor:"#2141ce",height:'50px' }} className="px-4 w-full inline-block    text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer fjalla">Courses</button></Link>
     
    </div>
    
    </div>
              <ul>
             <Link to="/profile">
             <li className=' text-gray-800 pb-1 ' style={{marginTop:"20px"}}>
        
        <div  className='pl-10 cursor-pointer border-t  flex justify-between py-5 pr-10'>
        <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
                  
                  <div className=" rounded-full  overflow-hidden" style={{width:"50px",height:"50px"}}>
              
    
        
        
                   
        {
                        photo.length == 0 ?  
                      <div
                        className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
                      style={{width:"50px",height:"50px"}}
                      >
                        {fullName[0]}
        
                      </div>
                      :
                      <img className="w-full h-full object-cover" alt='' src={photo} />
    
        
                      }
                </div>
                
                    <div className="ml-2 capitalize flex fjalla " style={{fontSize:'21px'}}>
               
                      {fullName}
                    </div>
                  </button>
                   
    
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
    </svg>
                        </div>
                  </li></Link>
                  
                  <li className=' text-gray-800 pb-1'><Link to="/mystudents">
                  <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                        <div className=' pl-10'>My Students </div>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{width:"4vw"}} fill="currentColor" class="bi bi-people w-7 h-7" viewBox="0 0 16 16">
    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
    </svg>
                        </div>
    
    
                  </Link></li>
    
                  <Link to='/transactions'>
                  <li className=' text-gray-800 pb-1'>
                  <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                        <div className=' pl-10'>Transactions </div>
                        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-activity w-7 h-7" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
    </svg>
                        </div>
                  </li>
                  </Link>
                  <Link to='/certificate'>
                  <li className=' text-gray-800 pb-1'>
                  <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                        <div className=' pl-10'>Certificates </div>
                        <img className='w-7 h-7' src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />
    
                        </div>
                  </li>
                  </Link>
    
                  <Link to='/notification'>
                  <li className=' text-gray-800 pb-1'>
                  <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                        <div className=' pl-10'>Notification </div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
      </svg>
                        </div>
                  </li>
                  </Link>
    
                  <li onClick={handleLogOut}className=' text-gray-800 pb-1'>
                  <div  className='cursor-pointer border-t  flex justify-between py-5 pr-10 borel'>
                        <div className=' pl-10'>Log out </div>
                     
                        <svg  className='mr-2' xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class=" bi bi-box-arrow-left" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
    <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
    </svg>
                        </div>
                 </li>
         
    
         
           
             
    
    
              </ul>
          </div>
    }
    
                </div>
                <div id='mobile'>
  <DashboardNav/>
                </div>
    
    
        </div>
            )

        })
    :

    <div>

    </div>

 
  )
}
