import React,{useEffect,useState} from 'react'
import Nav from './Nav'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import {auth, firestore} from '../firebase'
import { doc, onSnapshot,query,orderBy,collection,deleteDoc,setDoc } from 'firebase/firestore'
import { v4 } from 'uuid'
import { signOut } from 'firebase/auth'
import DashboardNav from '../dashboard/dashboardNav'
import moment from 'moment'
export default function PricingSignUp() {

    const [institutes,setInstitutes] = useState(false)
    const [oneMonth,setoneMonth] = useState(true)
    const [pop,setpop] = useState(false)
    let currentTime = moment()
    const [threeMonth,setthreeMonth] = useState(false)
    const [spinner,setspinner] = useState(false)
    const [user,setuser] = useState('Teacher')
    const [transaction,settransaction] = useState([])
    const currentDate = new Date()
    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1
    let Year = currentDate.getFullYear()
    let formattedDate = `${month}/${day}/${Year}`

    const [billingtimeStamp,setbillingtimeStamp] = useState()
    const [billingDateError,setbillingDateError] = useState(false)
    const [notificationPop,setnotificationPop] = useState(false)


  




    const [year,setyear] = useState(false)
    const [uid,setuid] = useState(false)

    const [User,setUser]=useState([])
    const [notification,setnotification]=useState([])

  const navigate = useNavigate() 


  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }
 
   function desktopSpinnerHandler(){
     setspinner(false)
    }
   
    
 

    function getCurrentDateTimeString() {
      const currentDate = new Date();
      return currentDate.toLocaleString('en-GB', {
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Use 24-hour format
      })
      .replace(/,/g, '')
      .replace(/\s/g, '');
    }
    
    // Example usage:
    const currentDateTimeString = getCurrentDateTimeString();

    console.log(currentDateTimeString);
 

    useEffect(() => {

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
        //  setcred(uid)
      setuid(uid);
         
  
     
      
  
    const coursesRef = collection(firestore,"AccountDetails",uid,"CoursesCreated");
    const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");
  
    const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));
  
    const teacherRef = doc(firestore,"TotalUsers",uid);
    const userRef = doc(firestore,"TotalUsers",uid);
  
    onSnapshot(userRef,snapshot=>{
     
      let main =[]
  
  
  
        main.push({...snapshot.data(),id:snapshot.id})
        
    setUser(main)

  
 
  
    })
    onSnapshot(notificationRef,snapshot=>{
      let main =[]
    
  
  
  
      snapshot.docs.forEach(doc=>{
        main.push({...doc.data(),id:doc.id})
       
      setnotification(main)
      })
  
      
      
    
  
    })
    onSnapshot(collection(firestore,'AccountDetails',uid,'Transaction'),doc=>{
      let temp = []
      doc.docs.forEach(snapshot=>{
        temp.push({...snapshot.data()})
        settransaction(temp)
      })
    

  })
    
    onSnapshot(coursesRef,snapshot=>{
      let main =[]
      let earning = 0;
      let students = 0;
      let markAverage = 0;
  
  

      
      
    
  
    })
  
  
         

             
   
    
                 
        
    
          
    
         
          
          // ...
        } else {
          console.log("logged out")
          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);
  



const convertTimestampToDate = (timestamp) => {
 
 if(timestamp){
  const date = timestamp.toDate();
  const currentDate = new Date();
  
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  let relativeTime = '';

  if (timeDifferenceInSeconds < secondsInMinute) {
    relativeTime = `${timeDifferenceInSeconds}s ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    relativeTime = `${minutes}m ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    relativeTime = `${hours}h ago`;
  } else if (timeDifferenceInSeconds < secondsInWeek) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    relativeTime = `${days}d ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
    relativeTime = `${weeks}w ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    relativeTime = `${months}mo ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    relativeTime = `${years}y ago`;
  }
  return relativeTime;

 }

};
const convertBillingToDate = (timestamp) => {
  if (timestamp) {
    const date = timestamp instanceof Date ? timestamp : timestamp.toDate();
    return date.toLocaleDateString();
  }
};

function mobileNavPop(){
  setpop(true)




}

// Mobile Nav Pop minimize
function mobileNavPopMinimize(){
setpop(false)



}


const popProfile = () =>{
  if(pop == true){
    setpop(false)
    setnotificationPop(false)

  }
  else{
  setpop(true)
  setnotificationPop(false)


  }
}
const popNotification = () =>{
if(notificationPop == true){
  setnotificationPop(false)
  setpop(false)
}
else{
setnotificationPop(true)
setpop(false)



}
}

  function billingDateErrorSet(){
    setbillingDateError(true)
  }
  function billingDateErrorSetRemove(){
    setbillingDateError(false)
  }

  function oneMonthTeacher(){
    if(oneMonth == false){
      setoneMonth(true)
      setyear(false)
      setthreeMonth(false)

    }

 

  }

  function threeMonthTeacher(){
    if(threeMonth == false){
      setthreeMonth(true)
      setoneMonth(false)
      setyear(false)

    }

  }
  function yearTeacher(){
    if(year == false){
      setoneMonth(false)
      setyear(true)
      setthreeMonth(false)

    }
 

  }


  function toInstitutesDesktop(){
    setuser('Institute')
     
   
   
     }
   
     function toTeacherDesktop(){
    setuser('Teacher')
 
        
        
   
     }
   
     
 
 
  

 


    useEffect(() => {
  
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;
     
        onSnapshot(doc(firestore,'TotalUsers',uid),snapshot=>{
            let temp = []
            temp.push({...snapshot.data()})
            setuser(temp)

        })
    
    
    
    
    
    
              
    
                
            
    
            
        
    
               
    
               
               
    
                  
        
    
          
    
         
          
          // ...
        } else {
          console.log("logged out")
          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);
    function removeNotification(id){
      const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
  
      setDoc(notificationRef,{
        seen:true
      },{merge:true})
      .then(() => {
        
        setnotification(prevArray => prevArray.filter(item => item.id !== id));
      })
      .catch((error) => {
      });
  
  
     }
    let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)
  return (
    

    <div>
        <div>
          {
            billingDateError == true &&
            <div id='billingDateDesktop'  className=' flex items-center justify-center'>
       
     
            <div className=' m-auto  rounded  my-5' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
     <div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 
     
     >
       
     <div class="relative p-6">
     <a     class="absolute top-1.5 right-1.5">
        <svg onClick={billingDateErrorSetRemove}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
            
     </a>
     <h1 class="text-3xl  anton" style={{fontSize:"25px"}}>Don't hesitate to contact us if you have any questions  </h1>
     
     <div class="flex flex-row mt-6 space-x-2 justify-evenly">
        <a   class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">+251923580987</a>
     </div>
     </div>
     </div>
     </div>  
     
     
        </div>
          }
      
     {
      User.length != 0 ?
        User.map(i=>{
        const{accountType,plan,fullName,photo,billingDate,isPending,status} = i

            return(
              accountType === 'Student' ?
              navigate('/dashboard')
              :
           (currentTime.isAfter(billingDate)|| plan === 'Free' ?
                // billingDate == ''  ?
              isPending == true?
           status == 'Passed' ?
          <div>
             <div id="desktop">
             <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' &&
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 

 
  }
      {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
              <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notificationFilter && notificationFilter.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notificationFilter && notificationFilter.length != 0 ?
              <div>
                {
              notificationFilter.map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
   <Link to='/notification'>
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
{notificationTitle} <br />
                </p>

                <p className='fjalla' style={{fontSize:'12px'}}>
                  {convertTimestampToDate(time)}

                </p>
              
</div>

</div></Link>

                )
              }) }
              <div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/notification"
                className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
              </div>
              :
              <div> 
              <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
          No new notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
              You'll be notified when new stuff happens

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
     
      
 
         {
            accountType == 'Institute' ?
            <div>
           
            <section
            
            className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
            >
            
            
            {
            oneMonth == true ?
            <div className=" css-ytebfg">
            
            <div className=" ">
            <svg
            className="css-jg7tbw"
            width={1620}
            height={129}
            style={{marginBottom:'-2'}}
            viewBox="0 0 1620 129"
            fill="#000000ff"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
            d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
            fill="#000000ff"
            />
            </svg>
            </div>
            <div className=" css-1lbfwu4">
            <div className=" css-194su0d">
            <p
            className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
            type="overline.lg.md"
            >
            Pricing
            </p>
            
            
                   <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                     
                     {
                     oneMonth == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>
            
            
                   }
            
                   {
                     threeMonth == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>
            
            
                   }
                                         {
                     year == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>
            
            
                   }
               </div>
            <div className="MuiBox-root css-oqthyc">
            
            </div>
            <div className=" css-1nt8b0">
            
            <div className=" css-14704d4">
            
            </div>
            
            <div className="MuiBox-root css-15vf48v">
            <h5
             className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
            
             style={{color:'white'}}
            >
             Institute Pricing
            </h5>
            </div>
            <div className=" css-1nr93" />
            </div>
            <div className=" css-k705nv">
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Free Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
                 A good starting point is to test things out first
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 It's free for life
               </p>
               <div className=" css-1cf6g5n">
                 <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  0 ETB
                 </h5>
             
               </div>
             </div>
             <div className=" css-fnszct">
               
              <Link to={`/dashboard`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   5 Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Courses with a maximum of 500 students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                  Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   50% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Certificates
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizes
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Basic Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
                 A perfect fit for small educational institutions
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 Membership Fee
               </p>
               <div className=" css-1cf6g5n">
               <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  20,000 ETB
                 </h5>
                 <p
                   className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                   type="medium"
                 >
                   /month
                 </p>
               </div>
             </div>
             <div className=" css-fnszct">
             <Link to={`/checkout/basic/month/${uid + currentDateTimeString}`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   15 Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Courses with a maximum of 1,500 students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Certificates
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizzes
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   15% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Premium Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
               Ideal for large-scale educational institutions  
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 Membership Fee
               </p>
               <div className=" css-1cf6g5n">
               <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  50,000 ETB
                 </h5>
                 <p
                   className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                   type="medium"
                 >
                   /month
                 </p>
               </div>
             </div>
             <div className=" css-fnszct">
             <Link to={`/checkout/premium/month/${uid + currentDateTimeString}`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Unlimited Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Unlimited students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                  Certificates  
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizzes
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   5% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            </div>
            
            </div>
            </div>
            
            
            
            <div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
            </div>
            :
            threeMonth == true ?
            <div className=" css-ytebfg">
            
            <div className=" ">
            <svg
            className="css-jg7tbw"
            width={1620}
            height={129}
            style={{marginBottom:'-2'}}
            viewBox="0 0 1620 129"
            fill="#000000ff"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
            d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
            fill="#000000ff"
            />
            </svg>
            </div>
            <div className=" css-1lbfwu4">
            <div className=" css-194su0d">
            <p
            className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
            type="overline.lg.md"
            >
            Pricing
            </p>
            
            
                   <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                     
                     {
                     oneMonth == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>
            
            
                   }
            
                   {
                     threeMonth == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>
            
            
                   }
                                         {
                     year == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>
            
            
                   }
               </div>
            <div className="MuiBox-root css-oqthyc">
            
            </div>
            <div className=" css-1nt8b0">
            
            <div className=" css-14704d4">
            
            </div>
            
            <div className="MuiBox-root css-15vf48v">
            <h5
             className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
            
             style={{color:'white'}}
            >
             Institute Pricing
            </h5>
            </div>
            <div className=" css-1nr93" />
            </div>
            <div className=" css-k705nv">
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Free Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
                 A good starting point is to test things out first
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 It's free for life
               </p>
               <div className=" css-1cf6g5n">
                 <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  0 ETB
                 </h5>
             
               </div>
             </div>
             <div className=" css-fnszct">
              <Link to={`/dashboard`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   5 Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Courses with a maximum of 500 students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                  Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   50% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Certificates
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizes
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Basic Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
                 A perfect fit for small educational institutions
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 Membership Fee
               </p>
               <div className=" css-1cf6g5n">
               <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  30,000 ETB
                 </h5>
                 <p
                   className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                   type="medium"
                 >
                   /3 Months
                 </p>
               </div>
             </div>
             <div className=" css-fnszct">
             <Link to={`/checkout/basic/3months/${uid + currentDateTimeString}`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   15 Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Courses with a maximum of 1,500 students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Certificates
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizzes
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   15% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Premium Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
               Ideal for large-scale educational institutions  
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 Membership Fee
               </p>
               <div className=" css-1cf6g5n">
               <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  75,000 ETB
                 </h5>
                 <p
                   className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                   type="medium"
                 >
                   /3 months
                 </p>
               </div>
             </div>
             <div className=" css-fnszct">
             <Link to={`/checkout/premium/3months/${uid + currentDateTimeString}`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Unlimited Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Unlimited students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                  Certificates  
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizzes
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   5% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            </div>
            
            </div>
            </div>
            
            
            
            <div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
            </div>
            :
            <div className=" css-ytebfg">
            
            <div className=" ">
            <svg
            className="css-jg7tbw"
            width={1620}
            height={129}
            style={{marginBottom:'-2'}}
            viewBox="0 0 1620 129"
            fill="#000000ff"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
            d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
            fill="#000000ff"
            />
            </svg>
            </div>
            <div className=" css-1lbfwu4">
            <div className=" css-194su0d">
            <p
            className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
            type="overline.lg.md"
            >
            Pricing
            </p>
            
            
                   <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                     
                     {
                     oneMonth == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>
            
            
                   }
            
                   {
                     threeMonth == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>
            
            
                   }
                                         {
                     year == true ?
                       <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>
            
            
                   }
               </div>
            <div className="MuiBox-root css-oqthyc">
            
            </div>
            <div className=" css-1nt8b0">
            
            <div className=" css-14704d4">
            
            </div>
            
            <div className="MuiBox-root css-15vf48v">
            <h5
             className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
            
             style={{color:'white'}}
            >
             Institute Pricing
            </h5>
            </div>
            <div className=" css-1nr93" />
            </div>
            <div className=" css-k705nv">
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Free Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
                 A good starting point is to test things out first
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 It's free for life
               </p>
               <div className=" css-1cf6g5n">
                 <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  0 ETB
                 </h5>
             
               </div>
             </div>
             <div className=" css-fnszct">
              <Link to={`/dashboard`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   5 Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Courses with a maximum of 500 students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                  Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   50% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Certificates
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizes
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Basic Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
                 A perfect fit for small educational institutions
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 Membership Fee
               </p>
               <div className=" css-1cf6g5n">
               <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  60,000 ETB
                 </h5>
                 <p
                   className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                   type="medium"
                 >
                   /year
                 </p>
               </div>
             </div>
             <div className=" css-fnszct">
             <Link to={`/checkout/basic/annual/${uid + currentDateTimeString}`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   15 Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Courses with a maximum of 1,500 students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Certificates
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizzes
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   15% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            <div className=" css-1c8o6vr">
            <div className=" css-vlx4w9">
             <div className=" css-12y5ho8" />
             <div className=" css-oxb5ly">
               <h5
                 className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                 type="d/small"
               >
                 Premium Plan
               </h5>
               <p
                 className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                 type="medium"
               >
               Ideal for large-scale educational institutions  
               </p>
             </div>
             <div className=" css-1y4proi">
               <p
                 className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                 type="small"
               >
                 Membership Fee
               </p>
               <div className=" css-1cf6g5n">
               <h5
                   className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                 >
                  150,000 ETB
                 </h5>
                 <p
                   className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                   type="medium"
                 >
                   /year
                 </p>
               </div>
             </div>
             <div className=" css-fnszct">
             <Link to={`/checkout/premium/annual/${uid + currentDateTimeString}`} className="css-1uggw86">
                 Get started
                 <div className="css-1e5u1e9">
                   <svg
                     className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                     focusable="false"
                     aria-hidden="true"
                     viewBox="0 0 24 24"
                     data-testid="ArrowForwardIcon"
                   >
                     <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                   </svg>
                 </div>
               </Link>
             </div>
            </div>
            <div className=" css-gl4o9m">
             <div className=" css-1kvsmvo">
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Unlimited Courses
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Unlimited students
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                  Certificates  
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Quizzes
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Progress Tracking and Assessment
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   5% Transactional Fee
                 </p>
               </div>
               <div className="flex items-center css-1x6lh4h">
                 <img
                   alt="greenTick-icon"
                   loading="lazy"
                   width={12}
                   height={12}
                   decoding="async"
                   data-nimg={1}
                   src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                   style={{ color: "transparent" }}
                 />
                 <p
                   className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                   type="small"
                 >
                   Teacher Support
                 </p>
               </div>
             </div>
            </div>
            
            </div>
            </div>
            
            </div>
            </div>
            
            
            
            <div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
            </div>
            }
            </section>
            </div>
         
         
         :
         accountType == 'Teacher' &&
         <section
           
           className=" relative z-10 overflow-hidden bg-white  pb-12  lg:pb-[90px]"
         >
            <h2
             style={{fontSize:'3vw'}}
                     className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
                   >
                     Our Pricing Plan
                   </h2>
 
           <div className='flex-col'>
      
         <div className="flex justify-between">
         <div>
         
         </div>
         <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
                         <div class="sm:-mx-0.5 flex">{
                           oneMonth == true ?
                             <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
         :
         <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={oneMonthTeacher}>1 Month</button>
         
         
                         }
         
                         {
                           threeMonth == true ?
                             <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
         :
         <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>
         
         
                         }
                                               {
                           year == true ?
                             <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
         :
         <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>
         
         
                         }
                         </div>
                     </div>
         </div>
         </div>
         {
         oneMonth == true ?
         <div className=" mx-auto">
     
         
  <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'40px'}}>
  <div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>

             <div
               className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
             >
               <span className="mb-4 block text-lg font-semibold text-primary">
                 Free Plan
               </span>
               <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                 <span>0 ETB</span>
               </h2>
           
           
               <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 500 students

              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Quizzes are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                25% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
           <Link to="/dashboard">
           <button
                 className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
               >
                 Start For Free
               </button></Link>
         
               <div>
                 <span className="absolute right-0 top-7 z-[-1]">
                   <svg
                     width="77"
                     height="172"
                     viewBox="0 0 77 172"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                     <defs>
                       <linearGradient
                         id="paint0_linear"
                         x1="86"
                         y1="0"
                         x2="86"
                         y2="172"
                         gradientUnits="userSpaceOnUse"
                       >
                         <stop stopColor="#3056D3" stopOpacity="0.09" />
                         <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                       </linearGradient>
                     </defs>
                   </svg>
                 </span>
                 <span className="absolute right-4 top-4 z-[-1]">
                   <svg
                     width="41"
                     height="89"
                     viewBox="0 0 41 89"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <circle
                       cx="38.9138"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 38.9138 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 38.9138 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 38.9138 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 38.9138 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 38.9138 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 38.9138 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 38.9138 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="1.42021"
                       r="1.42021"
                       transform="rotate(180 38.9138 1.42021)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 26.4157 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 26.4157 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 26.4157 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 26.4157 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 26.4157 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 26.4157 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 26.4157 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="1.4202"
                       r="1.42021"
                       transform="rotate(180 26.4157 1.4202)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 13.9177 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 13.9177 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 13.9177 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 13.9177 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 13.9177 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 13.9177 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 13.9177 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="1.42019"
                       r="1.42021"
                       transform="rotate(180 13.9177 1.42019)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 1.41963 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 1.41963 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 1.41963 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 1.41963 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 1.41963 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 1.41963 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 1.41963 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="1.4202"
                       r="1.42021"
                       transform="rotate(180 1.41963 1.4202)"
                       fill="#3056D3"
                     />
                   </svg>
                 </span>
               </div>
             </div>
           </div>
  <div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>

             <div
               className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
             >
               <span className="mb-4 block text-lg font-semibold text-primary">
                 Basic Plan
               </span>
               <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>3,000 ETB</span>

               </h2>
           
               <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                15 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 1,500 students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
             
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                10% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
           <Link to={`/checkout/basic/month/${uid + currentDateTimeString}`}>
         
           <button
                 className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
               >
                 Choose Plan
               </button></Link>
         
               <div>
                 <span className="absolute right-0 top-7 z-[-1]">
                   <svg
                     width="77"
                     height="172"
                     viewBox="0 0 77 172"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                     <defs>
                       <linearGradient
                         id="paint0_linear"
                         x1="86"
                         y1="0"
                         x2="86"
                         y2="172"
                         gradientUnits="userSpaceOnUse"
                       >
                         <stop stopColor="#3056D3" stopOpacity="0.09" />
                         <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                       </linearGradient>
                     </defs>
                   </svg>
                 </span>
                 <span className="absolute right-4 top-4 z-[-1]">
                   <svg
                     width="41"
                     height="89"
                     viewBox="0 0 41 89"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <circle
                       cx="38.9138"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 38.9138 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 38.9138 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 38.9138 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 38.9138 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 38.9138 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 38.9138 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 38.9138 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="1.42021"
                       r="1.42021"
                       transform="rotate(180 38.9138 1.42021)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 26.4157 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 26.4157 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 26.4157 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 26.4157 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 26.4157 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 26.4157 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 26.4157 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="1.4202"
                       r="1.42021"
                       transform="rotate(180 26.4157 1.4202)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 13.9177 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 13.9177 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 13.9177 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 13.9177 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 13.9177 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 13.9177 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 13.9177 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="1.42019"
                       r="1.42021"
                       transform="rotate(180 13.9177 1.42019)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 1.41963 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 1.41963 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 1.41963 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 1.41963 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 1.41963 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 1.41963 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 1.41963 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="1.4202"
                       r="1.42021"
                       transform="rotate(180 1.41963 1.4202)"
                       fill="#3056D3"
                     />
                   </svg>
                 </span>
               </div>
             </div>
           </div>
         
  <div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>

             <div
               className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
             >
               <span className="mb-4 block text-lg font-semibold text-primary">
                 Premium Plan
               </span>
               <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
         
               <span>8,000 ETB</span>

</h2>

<div className="mb-7">
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Unlimited Courses
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
  Unlimited students
  </p>
  <p className="mb-1 Mooli text-base leading-loose text-body-color">
  Certificates and Quizzes

  </p>

  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Progress Tracking and Assessment
  </p>

  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    5% Transactional Fee
  </p>

  <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Teacher Support
  </p>
</div>
           <Link to={`/checkout/premium/month/${uid + currentDateTimeString}`}>
         
            <button
                 className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"


               >
                 Choose Plan
               </button>
            </Link>
         
               <div>
                 <span className="absolute right-0 top-7 z-[-1]">
                   <svg
                     width="77"
                     height="172"
                     viewBox="0 0 77 172"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                     <defs>
                       <linearGradient
                         id="paint0_linear"
                         x1="86"
                         y1="0"
                         x2="86"
                         y2="172"
                         gradientUnits="userSpaceOnUse"
                       >
                         <stop stopColor="#3056D3" stopOpacity="0.09" />
                         <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                       </linearGradient>
                     </defs>
                   </svg>
                 </span>
                 <span className="absolute right-4 top-4 z-[-1]">
                   <svg
                     width="41"
                     height="89"
                     viewBox="0 0 41 89"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <circle
                       cx="38.9138"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 38.9138 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 38.9138 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 38.9138 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 38.9138 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 38.9138 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 38.9138 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 38.9138 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="38.9138"
                       cy="1.42021"
                       r="1.42021"
                       transform="rotate(180 38.9138 1.42021)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 26.4157 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 26.4157 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 26.4157 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 26.4157 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 26.4157 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 26.4157 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 26.4157 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="26.4157"
                       cy="1.4202"
                       r="1.42021"
                       transform="rotate(180 26.4157 1.4202)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 13.9177 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 13.9177 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 13.9177 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 13.9177 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 13.9177 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 13.9177 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 13.9177 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="13.9177"
                       cy="1.42019"
                       r="1.42021"
                       transform="rotate(180 13.9177 1.42019)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="87.4849"
                       r="1.42021"
                       transform="rotate(180 1.41963 87.4849)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="74.9871"
                       r="1.42021"
                       transform="rotate(180 1.41963 74.9871)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="62.4892"
                       r="1.42021"
                       transform="rotate(180 1.41963 62.4892)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="38.3457"
                       r="1.42021"
                       transform="rotate(180 1.41963 38.3457)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="13.634"
                       r="1.42021"
                       transform="rotate(180 1.41963 13.634)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="50.2754"
                       r="1.42021"
                       transform="rotate(180 1.41963 50.2754)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="26.1319"
                       r="1.42021"
                       transform="rotate(180 1.41963 26.1319)"
                       fill="#3056D3"
                     />
                     <circle
                       cx="1.41963"
                       cy="1.4202"
                       r="1.42021"
                       transform="rotate(180 1.41963 1.4202)"
                       fill="#3056D3"
                     />
                   </svg>
                 </span>
               </div>
             </div>
           </div>
         </div>
         </div>
         :
         threeMonth == true ?
         <div className=" mx-auto">
         
         
         <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'40px'}}>

<div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>
           <div
             className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
           >
             <span className="mb-4 block text-lg font-semibold text-primary">
               Free Plan
             </span>
             <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
               <span>0 ETB</span>
             </h2>
             <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 500 students

              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Quizzes are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                25% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
         <Link to="/dashboard">
           
         <button
               className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
             >
               Start For Free
             </button></Link>
         
             <div>
               <span className="absolute right-0 top-7 z-[-1]">
                 <svg
                   width="77"
                   height="172"
                   viewBox="0 0 77 172"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                   <defs>
                     <linearGradient
                       id="paint0_linear"
                       x1="86"
                       y1="0"
                       x2="86"
                       y2="172"
                       gradientUnits="userSpaceOnUse"
                     >
                       <stop stopColor="#3056D3" stopOpacity="0.09" />
                       <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                     </linearGradient>
                   </defs>
                 </svg>
               </span>
               <span className="absolute right-4 top-4 z-[-1]">
                 <svg
                   width="41"
                   height="89"
                   viewBox="0 0 41 89"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <circle
                     cx="38.9138"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 38.9138 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 38.9138 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 38.9138 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 38.9138 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 38.9138 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 38.9138 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 38.9138 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="1.42021"
                     r="1.42021"
                     transform="rotate(180 38.9138 1.42021)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 26.4157 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 26.4157 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 26.4157 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 26.4157 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 26.4157 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 26.4157 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 26.4157 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="1.4202"
                     r="1.42021"
                     transform="rotate(180 26.4157 1.4202)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 13.9177 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 13.9177 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 13.9177 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 13.9177 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 13.9177 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 13.9177 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 13.9177 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="1.42019"
                     r="1.42021"
                     transform="rotate(180 13.9177 1.42019)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 1.41963 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 1.41963 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 1.41963 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 1.41963 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 1.41963 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 1.41963 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 1.41963 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="1.4202"
                     r="1.42021"
                     transform="rotate(180 1.41963 1.4202)"
                     fill="#3056D3"
                   />
                 </svg>
               </span>
             </div>
           </div>
         </div>
<div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>

           <div
             className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
           >
             <span className="mb-4 block text-lg font-semibold text-primary">
               Basic Plan
             </span>
             <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
             <span>7,650 ETB</span>
          </h2>

             <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                15 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 1,500 students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
             
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                10% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
         
         <Link to={`/checkout/basic/3months/${uid + currentDateTimeString}`}>
         
         <button
               className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
             >
               Choose Plan
             </button></Link>
         
             <div>
               <span className="absolute right-0 top-7 z-[-1]">
                 <svg
                   width="77"
                   height="172"
                   viewBox="0 0 77 172"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                   <defs>
                     <linearGradient
                       id="paint0_linear"
                       x1="86"
                       y1="0"
                       x2="86"
                       y2="172"
                       gradientUnits="userSpaceOnUse"
                     >
                       <stop stopColor="#3056D3" stopOpacity="0.09" />
                       <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                     </linearGradient>
                   </defs>
                 </svg>
               </span>
               <span className="absolute right-4 top-4 z-[-1]">
                 <svg
                   width="41"
                   height="89"
                   viewBox="0 0 41 89"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <circle
                     cx="38.9138"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 38.9138 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 38.9138 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 38.9138 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 38.9138 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 38.9138 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 38.9138 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 38.9138 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="1.42021"
                     r="1.42021"
                     transform="rotate(180 38.9138 1.42021)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 26.4157 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 26.4157 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 26.4157 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 26.4157 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 26.4157 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 26.4157 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 26.4157 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="1.4202"
                     r="1.42021"
                     transform="rotate(180 26.4157 1.4202)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 13.9177 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 13.9177 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 13.9177 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 13.9177 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 13.9177 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 13.9177 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 13.9177 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="1.42019"
                     r="1.42021"
                     transform="rotate(180 13.9177 1.42019)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 1.41963 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 1.41963 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 1.41963 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 1.41963 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 1.41963 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 1.41963 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 1.41963 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="1.4202"
                     r="1.42021"
                     transform="rotate(180 1.41963 1.4202)"
                     fill="#3056D3"
                   />
                 </svg>
               </span>
             </div>
           </div>
         </div>
         
<div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>

           <div
             className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
           >
             <span className="mb-4 block text-lg font-semibold text-primary">
               Premium Plan
             </span>
             <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
         
             <span>20,400 ETB</span>

</h2>

 <div className="mb-7">
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Unlimited Courses
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Unlimited students
    </p>
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
    Certificates and Quizzes

    </p>

    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Progress Tracking and Assessment
    </p>

    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      5% Transactional Fee
    </p>
 
    <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Teacher Support
    </p>
  </div>
         <Link to={`/checkout/premium/3months/${uid + currentDateTimeString}`}>
         
          <button
               className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"

             >
               Choose Plan
             </button>
          </Link>
         
             <div>
               <span className="absolute right-0 top-7 z-[-1]">
                 <svg
                   width="77"
                   height="172"
                   viewBox="0 0 77 172"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                   <defs>
                     <linearGradient
                       id="paint0_linear"
                       x1="86"
                       y1="0"
                       x2="86"
                       y2="172"
                       gradientUnits="userSpaceOnUse"
                     >
                       <stop stopColor="#3056D3" stopOpacity="0.09" />
                       <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                     </linearGradient>
                   </defs>
                 </svg>
               </span>
               <span className="absolute right-4 top-4 z-[-1]">
                 <svg
                   width="41"
                   height="89"
                   viewBox="0 0 41 89"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <circle
                     cx="38.9138"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 38.9138 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 38.9138 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 38.9138 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 38.9138 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 38.9138 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 38.9138 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 38.9138 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="38.9138"
                     cy="1.42021"
                     r="1.42021"
                     transform="rotate(180 38.9138 1.42021)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 26.4157 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 26.4157 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 26.4157 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 26.4157 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 26.4157 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 26.4157 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 26.4157 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="26.4157"
                     cy="1.4202"
                     r="1.42021"
                     transform="rotate(180 26.4157 1.4202)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 13.9177 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 13.9177 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 13.9177 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 13.9177 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 13.9177 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 13.9177 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 13.9177 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="13.9177"
                     cy="1.42019"
                     r="1.42021"
                     transform="rotate(180 13.9177 1.42019)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="87.4849"
                     r="1.42021"
                     transform="rotate(180 1.41963 87.4849)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="74.9871"
                     r="1.42021"
                     transform="rotate(180 1.41963 74.9871)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="62.4892"
                     r="1.42021"
                     transform="rotate(180 1.41963 62.4892)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="38.3457"
                     r="1.42021"
                     transform="rotate(180 1.41963 38.3457)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="13.634"
                     r="1.42021"
                     transform="rotate(180 1.41963 13.634)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="50.2754"
                     r="1.42021"
                     transform="rotate(180 1.41963 50.2754)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="26.1319"
                     r="1.42021"
                     transform="rotate(180 1.41963 26.1319)"
                     fill="#3056D3"
                   />
                   <circle
                     cx="1.41963"
                     cy="1.4202"
                     r="1.42021"
                     transform="rotate(180 1.41963 1.4202)"
                     fill="#3056D3"
                   />
                 </svg>
               </span>
             </div>
           </div>
         </div>
         </div>
         </div>
         :
         <div className=" mx-auto">
   
         
   <div class="   flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"40px",marginTop:'40px'}}>

<div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>
         <div
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className="mb-4 block text-lg font-semibold text-primary">
             Free Plan
           </span>
           <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
             <span>0 ETB</span>
           </h2>
           <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 500 students

              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Quizzes are not available
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                25% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
         <Link to="/dashboard">
         <button
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Start For Free
           </button></Link>
         
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
         </div>
<div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>

         <div
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className="mb-4 block text-lg font-semibold text-primary">
             Basic Plan
           </span>
           <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
           <span>30,600 ETB</span>
        </h2>
 
        <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                15 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Courses with a maximum of 1,500 students
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certificates and Quizzes

              </p>
             
       
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>

              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                10% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
         
         <Link to={`/checkout/basic/annual/${uid + currentDateTimeString}`}>
 
         
         <button
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
         
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
         </div>
<div className="w-full " style={{minWidth:'400px',marginRight:'60px'}}>
         
         <div
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className="mb-4 block text-lg font-semibold text-primary">
             Premium Plan
           </span>
           <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
         
   
           <span>81,600 ETB</span>

</h2>

<div className="mb-7">
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Unlimited Courses
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Unlimited students
      </p>
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
      Certificates and Quizzes

      </p>

      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Progress Tracking and Assessment
      </p>

      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        5% Transactional Fee
      </p>
   
      <p className="mb-1 Mooli text-base leading-loose text-body-color">
        Teacher Support
      </p>
    </div>
         
         <Link to={`/checkout/premium/annual/${uid + currentDateTimeString}`}>
         
         <button
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"

           >
             Choose Plan
           </button>
         </Link>
         
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
         </div>
         </div>
         </div>
         }
         </section>
    
         }
         
         
         
         
         </div>

         <div id="mobile" className='mobile_steps ' style={{marginBottom:'30vw',paddingBottom:'10vw',width:"100%",margin:0}}>
        <nav className='flex justify-between navMobile  w-full '>

<Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={mobileNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"10vw",marginLeft:"3vw"}}  fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
</nav>

{
pop == true &&
<div id='popoutMobileHome' className='relative '>
<nav className='flex justify-between navMobile '>
<Link to="/" className=' ' style={{width:"32%",objectFit:"cover"}}> 
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
              </Link>

<div className='flex justify-end items-center' style={{width:'80%'}}>
<Link to='/courses'>
<svg xmlns="http://www.w3.org/2000/svg" style={{width:"6vw"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></Link>
<svg onClick={mobileNavPopMinimize} xmlns="http://www.w3.org/2000/svg"  style={{width:"5vw",marginLeft:"3vw"}} fill="currentColor" className="text-blue-800  bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>
</div>

</nav>
<ul >

<Link to="/signin">
<li className='listPopoutTablet border-b border-t' >Sign in</li>

</Link>
<Link to="/signup/student">
<li className='listPopoutTablet  border-b' >Sign up</li>


</Link>
<Link to="/aboutus">

<li className='listPopoutTablet  border-b' >About Us</li>
</Link>



<Link to="/pricing/teacher">
<li className='listPopoutTablet  border-b' >Pricing</li>
</Link>


</ul>
<Link to='/contact'>
<button type='submit' style={{backgroundColor:"#2141ce" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button></Link>
</div>
}

{ accountType == 'Institute' ?
 <section
 className=" relative z-10 overflow-hidden  pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>  
   <h2
   style={{fontSize:'9vw',marginTop:'10vw',paddingBottom:'5vw'}}
           className="text-center anton mb-4 text-gray-800 "
         >
           Our Pricing Plan
         </h2>
  

 {
     oneMonth == true ?
     <div className=" mx-auto">
  
 
 <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Basic
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>20,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/dashboard">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
 
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Premium
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>50,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
         
       <button
       style={{fontSize:'3vw',padding:'3vw'}}

             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
     </div>
     </div>
   </div>
   :
   threeMonth == true ?
   <div className=" mx-auto">
  
 
 <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Basic
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>30,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
 
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Premium
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>75,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
         
       <button
       style={{fontSize:'3vw',padding:'3vw'}}

             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
     </div>
     </div>
   </div>
 :
 <div className=" mx-auto">
  
 
 <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Basic
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>60,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
 
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Premium
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>150,000 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/institute">
         
       <button
       style={{fontSize:'3vw',padding:'3vw'}}

             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
     </div>
     </div>
   </div>
    }
   

   
</section>
:
<section
className=" relative z-10 overflow-hidden  pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>  
 <h2
 style={{fontSize:'9vw',marginTop:'10vw',paddingBottom:'5vw'}}
         className="text-center anton mb-4  text-gray-800"
       >
         Choose Pricing Plan
       </h2>
       <div className='flex-col'>

<div className="flex justify-between">
   <div style={{width:'40%'}}>

   </div>
   <div style={{width:'60%',marginTop:'5vw',marginBottom:'5vw'}} class="overflow-hidden  border rounded-lg">
                   <div class=" flex justify-between">{
                     oneMonth == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none   text-white bg-blue-800  rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg  fjalla"onClick={oneMonthTeacher}>1 Month</button>


                   }

                   {
                     threeMonth == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-blue-800 text-white  rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    py-1  text-gray-800   bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>


                   }
                                         {
                     year == true ?
                       <button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none    bg-blue-800 text-white rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'3vw',paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'3.5vw',paddingBottom:'3.5vw'}} class=" focus:outline-none   sm:w-auto py-1  text-gray-800  bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>


                   }
                   </div>
               </div>
 </div>
</div>

{
   oneMonth == true ?
   <div className=" mx-auto">


<div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
<div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Free Plan
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>0 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             5 Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             15% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to="/dashboard">
     <button
     style={{fontSize:'3vw',padding:'3vw'}}
           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
         >
           Start For Free
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>
     <div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Basic
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>1,000 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Unlimited Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
           10% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Certification and Recognition
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to={`/checkout/basic/month/${uid + currentDateTimeString}`}>

     <button
     style={{fontSize:'3vw',padding:'3vw'}}
           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>

     <div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Premium
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>5,000 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Unlimited Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
           5% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Certification and Recognition
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to={`/checkout/premium/month/${uid + currentDateTimeString}`}>
       
     <button
     style={{fontSize:'3vw',padding:'3vw'}}

           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>
   </div>
   </div>
 </div>
 :
 threeMonth == true ?
 <div className=" mx-auto">


 <div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
 <div class=" pb-10" style={{display:"flex",}}>
 <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Free Plan
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>0 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               5 Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               15% Transactional Fee
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/dashboard">
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Start For Free
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Basic
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>2,715 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             10% Transactional Fee
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
     <Link to={`/checkout/basic/3months/${uid + currentDateTimeString}`}>

       <button
       style={{fontSize:'3vw',padding:'3vw'}}
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
 
       <div className=" w-full " style={{width:'100vw'}}>
         <div
         style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
           className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
         >
           <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
            Premium
           </span>
           <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
             <span>13,600 ETB</span>
           </h2>
        
           <div className="mb-7">
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Unlimited Courses
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             5% Transactional Fee
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Certification and Recognition
             </p>
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Progress Tracking and Assessment
             </p>
          
             <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
               Teacher Support
             </p>
           </div>
       <Link to="/signup/teacher">
         
       <button
       style={{fontSize:'3vw',padding:'3vw'}}
 
             className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
           >
             Choose Plan
           </button></Link>
 
           <div>
             <span className="absolute right-0 top-7 z-[-1]">
               <svg
                 width="77"
                 height="172"
                 viewBox="0 0 77 172"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                 <defs>
                   <linearGradient
                     id="paint0_linear"
                     x1="86"
                     y1="0"
                     x2="86"
                     y2="172"
                     gradientUnits="userSpaceOnUse"
                   >
                     <stop stopColor="#3056D3" stopOpacity="0.09" />
                     <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                   </linearGradient>
                 </defs>
               </svg>
             </span>
             <span className="absolute right-4 top-4 z-[-1]">
               <svg
                 width="41"
                 height="89"
                 viewBox="0 0 41 89"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <circle
                   cx="38.9138"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 38.9138 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 38.9138 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 38.9138 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 38.9138 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 38.9138 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 38.9138 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 38.9138 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="38.9138"
                   cy="1.42021"
                   r="1.42021"
                   transform="rotate(180 38.9138 1.42021)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 26.4157 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 26.4157 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 26.4157 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 26.4157 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 26.4157 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 26.4157 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 26.4157 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="26.4157"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 26.4157 1.4202)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 13.9177 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 13.9177 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 13.9177 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 13.9177 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 13.9177 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 13.9177 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 13.9177 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="13.9177"
                   cy="1.42019"
                   r="1.42021"
                   transform="rotate(180 13.9177 1.42019)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="87.4849"
                   r="1.42021"
                   transform="rotate(180 1.41963 87.4849)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="74.9871"
                   r="1.42021"
                   transform="rotate(180 1.41963 74.9871)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="62.4892"
                   r="1.42021"
                   transform="rotate(180 1.41963 62.4892)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="38.3457"
                   r="1.42021"
                   transform="rotate(180 1.41963 38.3457)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="13.634"
                   r="1.42021"
                   transform="rotate(180 1.41963 13.634)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="50.2754"
                   r="1.42021"
                   transform="rotate(180 1.41963 50.2754)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="26.1319"
                   r="1.42021"
                   transform="rotate(180 1.41963 26.1319)"
                   fill="#3056D3"
                 />
                 <circle
                   cx="1.41963"
                   cy="1.4202"
                   r="1.42021"
                   transform="rotate(180 1.41963 1.4202)"
                   fill="#3056D3"
                 />
               </svg>
             </span>
           </div>
         </div>
       </div>
     </div>
     </div>
   </div>
:
<div className=" mx-auto">


<div class="  text-gray-500 flex   " style={{display:"flex",overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>
<div class=" pb-10" style={{display:"flex",}}>
<div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Free Plan
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>0 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             5 Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             15% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to="/dashboard">
     <button
     style={{fontSize:'3vw',padding:'3vw'}}
           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
         >
           Start For Free
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>
     <div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black',marginLeft:"6vw",marginRight:'6vw'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Basic
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>7,980 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Unlimited Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
           10% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Certification and Recognition
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to={`/checkout/premium/3months/${uid + currentDateTimeString}`}>

     <button
     style={{fontSize:'3vw',padding:'3vw'}}
           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent  text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>

     <div className=" w-full " style={{width:'100vw'}}>
       <div
       style={{paddingRight:'8vw',paddingLeft:'8vw',paddingTop:'12vw',paddingBottom:'12vw',color:'black'}}
         className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white  shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
       >
         <span className=" block text-lg font-semibold text-primary" style={{fontSize:"4.5vw",marginBottom:'5vw'}}>
          Premium
         </span>
         <h2 className="  font-bold  home_description-header" style={{fontSize:"9vw",color:'black',marginBottom:'8vw'}}>
           <span>39,900 ETB</span>
         </h2>
      
         <div className="mb-7">
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Unlimited Courses
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
           5% Transactional Fee
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Certification and Recognition
           </p>
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Progress Tracking and Assessment
           </p>
        
           <p className="mb-1 Mooli text-base leading-loose text-body-color" style={{fontSize:'3.5vw',marginBottom:'1.5vw'}}>
             Teacher Support
           </p>
         </div>
     <Link to={`/checkout/premium/annual/${uid + currentDateTimeString}`}>
       
     <button
     style={{fontSize:'3vw',padding:'3vw'}}

           className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
         >
           Choose Plan
         </button></Link>

         <div>
           <span className="absolute right-0 top-7 z-[-1]">
             <svg
               width="77"
               height="172"
               viewBox="0 0 77 172"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
               <defs>
                 <linearGradient
                   id="paint0_linear"
                   x1="86"
                   y1="0"
                   x2="86"
                   y2="172"
                   gradientUnits="userSpaceOnUse"
                 >
                   <stop stopColor="#3056D3" stopOpacity="0.09" />
                   <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                 </linearGradient>
               </defs>
             </svg>
           </span>
           <span className="absolute right-4 top-4 z-[-1]">
             <svg
               width="41"
               height="89"
               viewBox="0 0 41 89"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle
                 cx="38.9138"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 38.9138 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 38.9138 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 38.9138 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 38.9138 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 38.9138 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 38.9138 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 38.9138 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="38.9138"
                 cy="1.42021"
                 r="1.42021"
                 transform="rotate(180 38.9138 1.42021)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 26.4157 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 26.4157 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 26.4157 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 26.4157 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 26.4157 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 26.4157 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 26.4157 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="26.4157"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 26.4157 1.4202)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 13.9177 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 13.9177 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 13.9177 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 13.9177 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 13.9177 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 13.9177 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 13.9177 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="13.9177"
                 cy="1.42019"
                 r="1.42021"
                 transform="rotate(180 13.9177 1.42019)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="87.4849"
                 r="1.42021"
                 transform="rotate(180 1.41963 87.4849)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="74.9871"
                 r="1.42021"
                 transform="rotate(180 1.41963 74.9871)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="62.4892"
                 r="1.42021"
                 transform="rotate(180 1.41963 62.4892)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="38.3457"
                 r="1.42021"
                 transform="rotate(180 1.41963 38.3457)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="13.634"
                 r="1.42021"
                 transform="rotate(180 1.41963 13.634)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="50.2754"
                 r="1.42021"
                 transform="rotate(180 1.41963 50.2754)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="26.1319"
                 r="1.42021"
                 transform="rotate(180 1.41963 26.1319)"
                 fill="#3056D3"
               />
               <circle
                 cx="1.41963"
                 cy="1.4202"
                 r="1.42021"
                 transform="rotate(180 1.41963 1.4202)"
                 fill="#3056D3"
               />
             </svg>
           </span>
         </div>
       </div>
     </div>
   </div>
   </div>
 </div>
  }
 

 
</section>
}



</div>
<div id="tablet">
            
            <nav className='pt-8 flex justify-between items-center  px-3 w-full'>
       <div className='flex justify-between items-center w-full relative' >
<div className='flex justify-evenly'>
<Link to="/dashboard" className=' bg-blue-800 ml-5' style={{width:"55%",objectFit:"cover"}}> 
                    <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                      </Link>

            <div>
            
</div>


 
</div>


      <ul className='flex items-center'>
 {
  plan == 'Free' ?
  <Link to='/pricing'>
  <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer'>Upgrade Plan</p>
  </Link>

:
plan == 'Basic' && 
<Link to='/pricing'>

<p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer'>Upgrade Plan</p>
</Link>

 }
     <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer'>Need Help</p>
        <div  className="dropdown   mr-5 ">
          
          <div className='notibell relative mt-2'>
         {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
<div>
          <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
            <button    style={{color:"black"}}className=" inline-block relative">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
    </svg>
    <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
</button>
          </div>
          </div>
          </div>
          <div id='notification' className="hidden menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli'>Notifications</h1>
              <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notification && notification.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notification && notification.length != 0 ?
              notification.slice(0,3).map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='fjalla'>
                  {convertTimestampToDate(time)}

                </p>
              
</div>
<div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/transactions"
                className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
</div>

                )
              }) :
              <div> 
              <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
          No notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
        </div>
        <div className="flex  items-center">
        {/* user */}
        <div className="dropdown relative "onClick={popProfile} >
        
          <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
          
          <div className=" rounded-full  overflow-hidden" style={{width:"50px",height:"50px"}}>
      



           
{
                photo.length == 0 ?  
              <div
                className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
              style={{width:"50px",height:"50px"}}
              >
                {fullName[0]}

              </div>
              :
              <img className="w-full h-full object-cover" alt='' src={photo} />


              }
        </div>
        
            <div className="ml-2 capitalize flex ">
       
      
            </div>
          </button>
          <div id='profileData'  className="hidden text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
              className="cursor-pointer px-4 Mooli px-4 py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
        </div>
      
      </div>


      </ul>
      </div>

      </nav>

     

        {
           accountType == 'Institute' ?
          <section
        
          className=" relative z-10 overflow-hidden bg-white  pb-12  "
        >
           <h2
            style={{fontSize:'3vw'}}
                    className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
                  >
                    Our Pricing Plan
                  </h2>
          <div className='flex-col'>
 
        <div className="flex justify-between">
        <div>
        
        </div>
        <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
                        <div class="sm:-mx-0.5 flex">{
                          oneMonth == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
        :
        <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={oneMonthTeacher}>1 Month</button>
        
        
                        }
        
                        {
                          threeMonth == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
        :
        <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>
        
        
                        }
                                              {
                          year == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
        :
        <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>
        
        
                        }
                        </div>
                    </div>
        </div>
        </div>
        {
        oneMonth == true ?
        <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
         
             
        
            </div>
          </div>
        </div>
        
        <div className="-mx-4 flex flex-wrap justify-center">
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
               Basic
              </span>
              <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                <span>20,000 ETB</span>
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
               
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to={`/checkout/institute/basic/month/${uid + currentDateTimeString}`}>
            
          <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button></Link>
        
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary ">
               Premium
              </span>
              <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
                <span>50,000 ETB</span>
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
           <Link to={`/checkout/institute/premium/month/${uid + currentDateTimeString}`}>
           <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button>
           </Link>
        
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
        :
        threeMonth == true ?
        <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
          <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
        
           
        
          </div>
        </div>
        </div>
        
        <div className="-mx-4 flex flex-wrap justify-center">
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Basic
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>30,000 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
             
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to={`/checkout/basic/3months/${uid + currentDateTimeString}`}>
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Choose Plan
            </button></Link>
        
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Premium
            </span>
            <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
              <span>75,000 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/checkout/premium/3months">
        
         <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Choose Plan
            </button>
         </Link>
        
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        </div>
        </div>
        :
        <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
        <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
        
         
        
        </div>
        </div>
        </div>
        
        <div className="-mx-4 flex flex-wrap justify-center">
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Basic
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
            <span>60,000 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
           
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
        
        <Link to="/checkout/basic/annual">
        
        <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Choose Plan
          </button></Link>
        
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
        </div>
        
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Premium
          </span>
          <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
            <span>150,000 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
        <Link to="/checkout/premium/annual">
        
        <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Choose Plan
          </button>
        </Link>
        
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
        </div>
        </div>
        </div>
        }
        </section>
        
        
        :
        accountType == 'Teacher' &&
        <section
          
          className=" relative z-10 overflow-hidden bg-white  pb-12  lg:pb-[90px]"
        >
           <h2
            style={{fontSize:'3vw'}}
                    className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
                  >
                    Our Pricing Plan
                  </h2>

          <div className='flex-col'>
     
        <div className="flex justify-between">
        <div>
        
        </div>
        <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
                        <div class="sm:-mx-0.5 flex">{
                          oneMonth == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
        :
        <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={oneMonthTeacher}>1 Month</button>
        
        
                        }
        
                        {
                          threeMonth == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
        :
        <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>
        
        
                        }
                                              {
                          year == true ?
                            <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
        :
        <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>
        
        
                        }
                        </div>
                    </div>
        </div>
        </div>
        {
        oneMonth == true ?
        <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
         
             
        
            </div>
          </div>
        </div>
        
        <div className="-mx-4 flex flex-wrap justify-center">
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
                Free Plan
              </span>
              <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                <span>0 ETB</span>
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
               
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  5 Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  15% Transactional Fee
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to="/dashboard">
          <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Start For Free
              </button></Link>
        
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
                Basic Plan
              </span>
              <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                <span>1,000 ETB</span>
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
               
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
        
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  10% Transactional Fee
                </p>
             
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to={`/checkout/basic/month/${uid + currentDateTimeString}`}>
        
          <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button></Link>
        
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
            >
              <span className="mb-4 block text-lg font-semibold text-primary">
                Premium Plan
              </span>
              <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
        
                <span>5,000 ETB</span>
        
              </h2>
              <p
                className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
              >
                
              </p>
              <div className="mb-7">
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Unlimited Courses
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Certification and Recognition
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Progress Tracking and Assessment
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  5% Transactional Fee
                </p>
                <p className="mb-1 Mooli text-base leading-loose text-body-color">
                  Teacher Support
                </p>
              </div>
          <Link to={`/checkout/premium/month/${uid + currentDateTimeString}`}>
        
           <button
                className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
              >
                Choose Plan
              </button>
           </Link>
        
              <div>
                <span className="absolute right-0 top-7 z-[-1]">
                  <svg
                    width="77"
                    height="172"
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="86"
                        y1="0"
                        x2="86"
                        y2="172"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[-1]">
                  <svg
                    width="41"
                    height="89"
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
        :
        threeMonth == true ?
        <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
          <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
        
           
        
          </div>
        </div>
        </div>
        
        <div className="-mx-4 flex flex-wrap justify-center">
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Free Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>0 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
             
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5 Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                15% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to="/dashboard">
          
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Start For Free
            </button></Link>
        
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Basic Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
              <span>2,715 ETB</span>
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
             
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
        
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                10% Transactional Fee
              </p>
           
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        
        <Link to={`/checkout/basic/3months/${uid + currentDateTimeString}`}>
        
        <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Choose Plan
            </button></Link>
        
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
          <div
            className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
          >
            <span className="mb-4 block text-lg font-semibold text-primary">
              Premium Plan
            </span>
            <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
        
              <span>13,600 ETB</span>
        
            </h2>
            <p
              className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
            >
              
            </p>
            <div className="mb-7">
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Unlimited Courses
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Certification and Recognition
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Progress Tracking and Assessment
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                5% Transactional Fee
              </p>
              <p className="mb-1 Mooli text-base leading-loose text-body-color">
                Teacher Support
              </p>
            </div>
        <Link to={`/checkout/premium/3months/${uid + currentDateTimeString}`}>
        
         <button
              className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
            >
              Choose Plan
            </button>
         </Link>
        
            <div>
              <span className="absolute right-0 top-7 z-[-1]">
                <svg
                  width="77"
                  height="172"
                  viewBox="0 0 77 172"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="86"
                      y1="0"
                      x2="86"
                      y2="172"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3056D3" stopOpacity="0.09" />
                      <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span className="absolute right-4 top-4 z-[-1]">
                <svg
                  width="41"
                  height="89"
                  viewBox="0 0 41 89"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="38.9138"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 38.9138 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 38.9138 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 38.9138 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 38.9138 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 38.9138 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 38.9138 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 38.9138 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="38.9138"
                    cy="1.42021"
                    r="1.42021"
                    transform="rotate(180 38.9138 1.42021)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 26.4157 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 26.4157 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 26.4157 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 26.4157 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 26.4157 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 26.4157 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 26.4157 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="26.4157"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 26.4157 1.4202)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 13.9177 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 13.9177 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 13.9177 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 13.9177 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 13.9177 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 13.9177 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 13.9177 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="13.9177"
                    cy="1.42019"
                    r="1.42021"
                    transform="rotate(180 13.9177 1.42019)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="87.4849"
                    r="1.42021"
                    transform="rotate(180 1.41963 87.4849)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="74.9871"
                    r="1.42021"
                    transform="rotate(180 1.41963 74.9871)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="62.4892"
                    r="1.42021"
                    transform="rotate(180 1.41963 62.4892)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="38.3457"
                    r="1.42021"
                    transform="rotate(180 1.41963 38.3457)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="13.634"
                    r="1.42021"
                    transform="rotate(180 1.41963 13.634)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="50.2754"
                    r="1.42021"
                    transform="rotate(180 1.41963 50.2754)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="26.1319"
                    r="1.42021"
                    transform="rotate(180 1.41963 26.1319)"
                    fill="#3056D3"
                  />
                  <circle
                    cx="1.41963"
                    cy="1.4202"
                    r="1.42021"
                    transform="rotate(180 1.41963 1.4202)"
                    fill="#3056D3"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        </div>
        </div>
        :
        <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
        <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
        
         
        
        </div>
        </div>
        </div>
        
        <div className="-mx-4 flex flex-wrap justify-center">
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Free Plan
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
            <span>0 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
           
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              5 Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              15% Transactional Fee
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
        <Link to="/dashboard">
        <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Start For Free
          </button></Link>
        
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
        </div>
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Basic Plan
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
            <span>7,980 ETB</span>
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
           
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
        
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              10% Transactional Fee
            </p>
         
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
        
        <Link to={`/checkout/basic/annual/${uid + currentDateTimeString}`}>

        
        <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Choose Plan
          </button></Link>
        
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
        </div>
        
        <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
        >
          <span className="mb-4 block text-lg font-semibold text-primary">
            Premium Plan
          </span>
          <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
        
          <span>39,900 ETB</span>
        
          </h2>
          <p
            className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
          >
            
          </p>
          <div className="mb-7">
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Unlimited Courses
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Certification and Recognition
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Progress Tracking and Assessment
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              5% Transactional Fee
            </p>
            <p className="mb-1 Mooli text-base leading-loose text-body-color">
              Teacher Support
            </p>
          </div>
        
        <Link to={`/checkout/premium/annual/${uid + currentDateTimeString}`}>
        
        <button
            className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
          >
            Choose Plan
          </button>
        </Link>
        
          <div>
            <span className="absolute right-0 top-7 z-[-1]">
              <svg
                width="77"
                height="172"
                viewBox="0 0 77 172"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="86"
                    y1="0"
                    x2="86"
                    y2="172"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#3056D3" stopOpacity="0.09" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span className="absolute right-4 top-4 z-[-1]">
              <svg
                width="41"
                height="89"
                viewBox="0 0 41 89"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="38.9138"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 38.9138 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 38.9138 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 38.9138 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 38.9138 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 38.9138 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 38.9138 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 38.9138 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="38.9138"
                  cy="1.42021"
                  r="1.42021"
                  transform="rotate(180 38.9138 1.42021)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 26.4157 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 26.4157 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 26.4157 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 26.4157 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 26.4157 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 26.4157 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 26.4157 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="26.4157"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 26.4157 1.4202)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 13.9177 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 13.9177 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 13.9177 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 13.9177 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 13.9177 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 13.9177 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 13.9177 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="13.9177"
                  cy="1.42019"
                  r="1.42021"
                  transform="rotate(180 13.9177 1.42019)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="87.4849"
                  r="1.42021"
                  transform="rotate(180 1.41963 87.4849)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="74.9871"
                  r="1.42021"
                  transform="rotate(180 1.41963 74.9871)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="62.4892"
                  r="1.42021"
                  transform="rotate(180 1.41963 62.4892)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="38.3457"
                  r="1.42021"
                  transform="rotate(180 1.41963 38.3457)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="13.634"
                  r="1.42021"
                  transform="rotate(180 1.41963 13.634)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="50.2754"
                  r="1.42021"
                  transform="rotate(180 1.41963 50.2754)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="26.1319"
                  r="1.42021"
                  transform="rotate(180 1.41963 26.1319)"
                  fill="#3056D3"
                />
                <circle
                  cx="1.41963"
                  cy="1.4202"
                  r="1.42021"
                  transform="rotate(180 1.41963 1.4202)"
                  fill="#3056D3"
                />
              </svg>
            </span>
          </div>
        </div>
        </div>
        </div>
        </div>
        }
        </section>
   
        }
        
        
        
        
        </div>
          </div>
     :
     transaction && transaction.map(i=>{
       const {chosenMethod,amount} = i
       return(
        <div >
<DashboardNav/>
         <div class="h-screen   flex items-center" id='desktop'>
         <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                 <div class="w-full lg:w-1/2 mx-8">
                     <div style={{fontSize:'3vw',lineHeight:'2vw'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                     <p className='fjalla flex flex-col' style={{fontSize:'1.5vw',lineHeight:'1.5vw'}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                     </div>
                
                     <Link to={`/dashboard`}>
                     <button class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                   
                     Check out my account until the payment is verified
                                 </button>
                     </Link>      
      
                 </div>
             <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
             <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
             </div>
         
         </div>
     </div>
     <div class="h-screen   flex items-center" id='mobile'>
         <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                 <div class="w-full lg:w-1/2 mx-8">
                     <h1 style={{fontSize:'6vw'}} class="text-7xl text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete</h1>
                     <p className='fjalla flex flex-col' style={{fontSize:'3vw',lineHeight:'3vw'}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                
                     <Link to={`/dashboard`}>
                     <button style={{fontSize:'3vw',lineHeight:'3vw',marginTop:'4vw'}}class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                   
                     Check out my account until the payment is verified
                                 </button>
                     </Link>      
      
                 </div>
             <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
             <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
             </div>
         
         </div>
     </div>
     </div>

       )
     })
   
     
     
              :
              <div >
              <div id='desktop'>
                     <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
        <div className='flex justify-between items-center w-full relative mb-5' >
 <div className='flex justify-evenly'>

<Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                     <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

             <div>

</div>


  
 </div>
 
 
       <ul className='flex items-center'>
  {
   plan == 'Free' ?
   <Link to='/pricing'>
   <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
   </Link>
 
 :
 plan == 'Basic' && 
 <Link to='/pricing'>
 
 <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
 </Link>
 
  }
      <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p>
         <div  className="dropdown   mr-5 ">
           
           <div className='notibell relative mt-2'>
          {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
 <div>
           <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
             <button    style={{color:"black"}}className=" inline-block relative">
     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
     </svg>
     <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
 </button>
           </div>
           </div>
           </div>
           {
            notificationPop == true &&
            <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
            {/* top */}
            <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
              <h1 className='py-3 Mooli'>Notifications</h1>
              <div className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                {/* <strong>{notification.length}</strong> */}
                {notification && notification.length}
              </div>
            </div>
            <hr />
            {/* end top */}
            {/* body */}
            {/* item */}
          
            {/* end item */}
            {/* item */}
  
            {/* end body */}
            {/* bottom */}
         

            {
              notification && notification.length != 0 ?
              notification.slice(0,3).map(i=>{
                const {notificationTitle,declinedReason,time,id} = i
                return(
                  <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>

<div className='relative  flex items-center justify-between  '>
<svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p className='text-gray-600  py-5 px-3 fjalla' style={{width:'90%',fontSize:'18px'}}>
{notificationTitle} <br />
<span className='Mooli text-gray-400' style={{fontSize:'15px'}}>{declinedReason && declinedReason.slice(0,100)}</span>
                </p>

                <p className='fjalla'>
                  {convertTimestampToDate(time)}

                </p>
              
</div>
<div className=" px-4 py-2 mt-2 fjalla">
              <Link
                to="/transactions"
                className="border border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
              >
                view all
              </Link>
            
            </div>
</div>

                )
              }) :
              <div> 
              <h1 className='Mooli text-center pt-4 pb-2' style={{fontSize:"30px"}}>
          No notifications

              </h1>
              <p className='text-gray-400 text-center pb-4 px-3'>
We'll let you know when deadlines are approaching, or there is a course update

              </p>
          </div> 
            }



     
         
            {/* end bottom */}
          </div>
           }
       
         </div>
         <div className="flex  items-center">
         {/* user */}
         <div className="dropdown relative "onClick={popProfile} >
         
           <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
           
           <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
       
 
 
 
            
 {
                 photo.length == 0 ?  
               <div
                 className="flex  items-center justify-center h-12 w-12 bg-blue-300 rounded-full w-12 h-12 m-auto rounded-full object-cover "
               style={{width:"40px",height:"40px",fontSize:'12px'}}
               >
                 {fullName[0]}
 
               </div>
               :
               <img className="w-full h-full object-cover" alt='' src={photo} />
 
 
               }
         </div>
         
             <div className="ml-2 capitalize flex ">
        
       
             </div>
           </button>
           {
            pop == true && 
            <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
            {/* item */}
            <Link to="/profile"
            style={{fontSize:'12px'}}
              className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
     
               Profile
            </Link>
            {/* end item */}
            {/* item */}
          
    
            {/* item */}
            <div
            onClick={handleLogOut}
            style={{fontSize:'12px'}}

              className="cursor-pointer px-4 Mooli px-4 py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
            >
              Log out
            </div>
            {/* end item */}
          </div>
           }
         
         </div>
       
       </div>
 
 
       </ul>
       </div>
 
       </nav>
              <section

              className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
            >
            
            
            {
            oneMonth == true ?
            <div className=" css-ytebfg">
            
            <div className=" ">
              <svg
                className="css-jg7tbw"
                width={1620}
                height={129}
                style={{marginBottom:'-2'}}
                viewBox="0 0 1620 129"
                fill="#000000ff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
                  fill="#000000ff"
                />
              </svg>
            </div>
            <div className=" css-1lbfwu4">
              <div className=" css-194su0d">
              <p
                  className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
                  type="overline.lg.md"
                >
                  Pricing
                </p>
            
            
                            <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                              
                              {
                              oneMonth == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>
            
            
                            }
            
                            {
                              threeMonth == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>
            
            
                            }
                                                  {
                              year == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>
            
            
                            }
                        </div>
                <div className="MuiBox-root css-oqthyc">
               
                </div>
                <div className=" css-1nt8b0">
                  
                  <div className=" css-14704d4">
                   
                  </div>
            
                  <div className="MuiBox-root css-15vf48v">
                    <h5
                      className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
                     
                      style={{color:'white'}}
                    >
                      Institute Pricing
                    </h5>
                  </div>
                  <div className=" css-1nr93" />
                </div>
                <div className=" css-k705nv">
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Free Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                          A good starting point is to test things out first
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          It's free for life
                        </p>
                        <div className=" css-1cf6g5n">
                          <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           0 ETB
                          </h5>
                      
                        </div>
                      </div>
                      <div className=" css-fnszct">
                        
                       <Link to={`/dashboard`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            5 Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Courses with a maximum of 500 students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                           Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            50% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Certificates
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizes
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Basic Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                          A perfect fit for small educational institutions
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          Membership Fee
                        </p>
                        <div className=" css-1cf6g5n">
                        <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           20,000 ETB
                          </h5>
                          <p
                            className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                            type="medium"
                          >
                            /month
                          </p>
                        </div>
                      </div>
                      <div className=" css-fnszct">
                      <Link to={`/checkout/basic/month/${uid + currentDateTimeString}`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            15 Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Courses with a maximum of 1,500 students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Certificates
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizzes
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            15% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Premium Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                        Ideal for large-scale educational institutions  
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          Membership Fee
                        </p>
                        <div className=" css-1cf6g5n">
                        <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           50,000 ETB
                          </h5>
                          <p
                            className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                            type="medium"
                          >
                            /month
                          </p>
                        </div>
                      </div>
                      <div className=" css-fnszct">
                      <Link to={`/checkout/premium/month/${uid + currentDateTimeString}`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Unlimited Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Unlimited students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                           Certificates  
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizzes
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            5% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
               
              </div>
            </div>
            
            
            
            <div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
            </div>
            :
            threeMonth == true ?
            <div className=" css-ytebfg">
            
            <div className=" ">
              <svg
                className="css-jg7tbw"
                width={1620}
                height={129}
                style={{marginBottom:'-2'}}
                viewBox="0 0 1620 129"
                fill="#000000ff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
                  fill="#000000ff"
                />
              </svg>
            </div>
            <div className=" css-1lbfwu4">
              <div className=" css-194su0d">
              <p
                  className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
                  type="overline.lg.md"
                >
                  Pricing
                </p>
            
            
                            <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                              
                              {
                              oneMonth == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>
            
            
                            }
            
                            {
                              threeMonth == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>
            
            
                            }
                                                  {
                              year == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>
            
            
                            }
                        </div>
                <div className="MuiBox-root css-oqthyc">
               
                </div>
                <div className=" css-1nt8b0">
                  
                  <div className=" css-14704d4">
                   
                  </div>
            
                  <div className="MuiBox-root css-15vf48v">
                    <h5
                      className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
                     
                      style={{color:'white'}}
                    >
                      Institute Pricing
                    </h5>
                  </div>
                  <div className=" css-1nr93" />
                </div>
                <div className=" css-k705nv">
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Free Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                          A good starting point is to test things out first
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          It's free for life
                        </p>
                        <div className=" css-1cf6g5n">
                          <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           0 ETB
                          </h5>
                      
                        </div>
                      </div>
                      <div className=" css-fnszct">
                       <Link to={`/dashboard`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            5 Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Courses with a maximum of 500 students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                           Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            50% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Certificates
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizes
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Basic Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                          A perfect fit for small educational institutions
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          Membership Fee
                        </p>
                        <div className=" css-1cf6g5n">
                        <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           30,000 ETB
                          </h5>
                          <p
                            className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                            type="medium"
                          >
                            /3 Months
                          </p>
                        </div>
                      </div>
                      <div className=" css-fnszct">
                      <Link to={`/checkout/basic/3months/${uid + currentDateTimeString}`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            15 Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Courses with a maximum of 1,500 students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Certificates
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizzes
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            15% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Premium Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                        Ideal for large-scale educational institutions  
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          Membership Fee
                        </p>
                        <div className=" css-1cf6g5n">
                        <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           75,000 ETB
                          </h5>
                          <p
                            className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                            type="medium"
                          >
                            /3 months
                          </p>
                        </div>
                      </div>
                      <div className=" css-fnszct">
                      <Link to={`/checkout/premium/3months/${uid + currentDateTimeString}`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Unlimited Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Unlimited students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                           Certificates  
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizzes
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            5% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
               
              </div>
            </div>
            
            
            
            <div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
            </div>
            :
            <div className=" css-ytebfg">
            
            <div className=" ">
              <svg
                className="css-jg7tbw"
                width={1620}
                height={129}
                style={{marginBottom:'-2'}}
                viewBox="0 0 1620 129"
                fill="#000000ff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
                  fill="#000000ff"
                />
              </svg>
            </div>
            <div className=" css-1lbfwu4">
              <div className=" css-194su0d">
              <p
                  className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
                  type="overline.lg.md"
                >
                  Pricing
                </p>
            
            
                            <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                              
                              {
                              oneMonth == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>
            
            
                            }
            
                            {
                              threeMonth == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>
            
            
                            }
                                                  {
                              year == true ?
                                <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
            :
            <button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>
            
            
                            }
                        </div>
                <div className="MuiBox-root css-oqthyc">
               
                </div>
                <div className=" css-1nt8b0">
                  
                  <div className=" css-14704d4">
                   
                  </div>
            
                  <div className="MuiBox-root css-15vf48v">
                    <h5
                      className="MuiTypography-root MuiTypography-h5 css-c8mpmg"
                     
                      style={{color:'white'}}
                    >
                      Institute Pricing
                    </h5>
                  </div>
                  <div className=" css-1nr93" />
                </div>
                <div className=" css-k705nv">
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Free Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                          A good starting point is to test things out first
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          It's free for life
                        </p>
                        <div className=" css-1cf6g5n">
                          <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           0 ETB
                          </h5>
                      
                        </div>
                      </div>
                      <div className=" css-fnszct">
                       <Link to={`/dashboard`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            5 Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Courses with a maximum of 500 students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                           Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            50% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Certificates
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizes
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Basic Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                          A perfect fit for small educational institutions
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          Membership Fee
                        </p>
                        <div className=" css-1cf6g5n">
                        <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           60,000 ETB
                          </h5>
                          <p
                            className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                            type="medium"
                          >
                            /year
                          </p>
                        </div>
                      </div>
                      <div className=" css-fnszct">
                      <Link to={`/checkout/basic/annual/${uid + currentDateTimeString}`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            15 Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Courses with a maximum of 1,500 students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Certificates
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizzes
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            15% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className=" css-1c8o6vr">
                    <div className=" css-vlx4w9">
                      <div className=" css-12y5ho8" />
                      <div className=" css-oxb5ly">
                        <h5
                          className="MuiTypography-root MuiTypography-h5 css-u4eke3"
                          type="d/small"
                        >
                          Premium Plan
                        </h5>
                        <p
                          className="MuiTypography-root MuiTypography-body1 css-1yxzade"
                          type="medium"
                        >
                        Ideal for large-scale educational institutions  
                        </p>
                      </div>
                      <div className=" css-1y4proi">
                        <p
                          className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
                          type="small"
                        >
                          Membership Fee
                        </p>
                        <div className=" css-1cf6g5n">
                        <h5
                            className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
                          >
                           150,000 ETB
                          </h5>
                          <p
                            className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                            type="medium"
                          >
                            /year
                          </p>
                        </div>
                      </div>
                      <div className=" css-fnszct">
                      <Link to={`/checkout/premium/annual/${uid + currentDateTimeString}`} className="css-1uggw86">
                          Get started
                          <div className="css-1e5u1e9">
                            <svg
                              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowForwardIcon"
                            >
                              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className=" css-gl4o9m">
                      <div className=" css-1kvsmvo">
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Unlimited Courses
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Unlimited students
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                           Certificates  
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Quizzes
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Progress Tracking and Assessment
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            5% Transactional Fee
                          </p>
                        </div>
                        <div className="flex items-center css-1x6lh4h">
                          <img
                            alt="greenTick-icon"
                            loading="lazy"
                            width={12}
                            height={12}
                            decoding="async"
                            data-nimg={1}
                            src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                            style={{ color: "transparent" }}
                          />
                          <p
                            className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                            type="small"
                          >
                            Teacher Support
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
               
              </div>
            </div>
            
            
            
            <div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
            </div>
            }
            </section>
       </div>
       <div id="mobile">

      <DashboardNav/>
 

  <section

  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
>


{
oneMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#000000ff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#000000ff"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/dashboard`} className="css-1uggw86">
              Start for free
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               20,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/checkout/basic/month/${uid + currentDateTimeString}`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               50,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /month
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
threeMonth == true ?
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#000000ff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#000000ff"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               30,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               75,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /3 months
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
:
<div className=" css-ytebfg">

<div className=" ">
  <svg
    className="css-jg7tbw"
    width={1620}
    height={129}
    style={{marginBottom:'-2'}}
    viewBox="0 0 1620 129"
    fill="#000000ff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1620 128.5H0C0 128.5 42 0.499923 511 0.5C980 0.500077 1545.76 93.491 1620 128.5Z"
      fill="#000000ff"
    />
  </svg>
</div>
<div className=" css-1lbfwu4" style={{padding:'15px'}}>
  <div className=" css-194su0d">
  <p
      className="MuiTypography-root MuiTypography-body1 css-1l7xd1z"
      type="overline.lg.md"
    >
      Pricing
    </p>


                <div class="sm:-mx-0.5 flex border rounded-lg w-full justify-evenly">
                  
                  {
                  oneMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}}class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={oneMonthTeacher}>1 Month</button>


                }

                {
                  threeMonth == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}} class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={threeMonthTeacher}>3 Months</button>


                }
                                      {
                  year == true ?
                    <button style={{fontSize:'11px',width:'33%',color:'black'}}  class="w-full focus:outline-none px-3 py-3  sm:w-auto py-1 sm:mx-0.5 text-white bg-[#31ffaa] rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
:
<button style={{fontSize:'11px',width:'33%',color:'white'}} class=" focus:outline-none px-3  sm:w-auto py-1 sm:mx-0.5 text-gray-800   bg-transparent rounded-lg hover:border fjalla"onClick={yearTeacher}>Annual</button>


                }
            </div>
    <div className="MuiBox-root css-oqthyc">
   
    </div>
    <div className=" css-1nt8b0">
      
      <div className=" css-14704d4">
       
      </div>

      <div className="">
        <h5
          className=" barlow"
         
          style={{color:'white',fontSize:'8vw'}}
        >
          Institute Pricing
        </h5>
      </div>
      <div className=" css-1nr93" />
    </div>
    <div className=" css-k705nv" style={{overflow:'scroll'}}>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Free Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A good starting point is to test things out first
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              It's free for life
            </p>
            <div className=" css-1cf6g5n">
              <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               0 ETB
              </h5>
          
            </div>
          </div>
          <div className=" css-fnszct">
           <Link to={`/signup/institute`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                50% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/cross-icon.webp?alt=media&token=7560c9c1-580d-4b31-8fb3-e34f4917d7cb"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizes
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Basic Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
              A perfect fit for small educational institutions
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               60,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15 Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Courses with a maximum of 1,500 students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Certificates
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                15% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
      <div className=" css-1c8o6vr" style={{minWidth:'300px'}}>
        <div className=" css-vlx4w9">
          <div className=" css-12y5ho8" />
          <div className=" css-oxb5ly">
            <h5
              className="MuiTypography-root MuiTypography-h5 css-u4eke3"
              type="d/small"
            >
              Premium Plan
            </h5>
            <p
              className="MuiTypography-root MuiTypography-body1 css-1yxzade"
              type="medium"
            >
            Ideal for large-scale educational institutions  
            </p>
          </div>
          <div className=" css-1y4proi">
            <p
              className="MuiTypography-root MuiTypography-body1 md-tight primary css-xpxqog"
              type="small"
            >
              Membership Fee
            </p>
            <div className=" css-1cf6g5n">
            <h5
                className="MuiTypography-root MuiTypography-h5 anton" style={{fontSize:'30px'}}
              >
               150,000 ETB
              </h5>
              <p
                className="MuiTypography-root MuiTypography-body1 primary css-1yxzade"
                type="medium"
              >
                /year
              </p>
            </div>
          </div>
          <div className=" css-fnszct">
          <Link to={`/signup/institute`} className="css-1uggw86">
              Get started
              <div className="css-1e5u1e9">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1bz8x1z "
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="ArrowForwardIcon"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                </svg>
              </div>
            </Link>
          </div>
        </div>
        <div className=" css-gl4o9m">
          <div className=" css-1kvsmvo">
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited Courses
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Unlimited students
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
               Certificates  
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Quizzes
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Progress Tracking and Assessment
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                5% Transactional Fee
              </p>
            </div>
            <div className="flex items-center css-1x6lh4h">
              <img
                alt="greenTick-icon"
                loading="lazy"
                width={12}
                height={12}
                decoding="async"
                data-nimg={1}
                src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/tick-mark-icon.webp?alt=media&token=6a817c00-ceea-4931-b1fd-78173a860215"
                style={{ color: "transparent" }}
              />
              <p
                className="MuiTypography-root MuiTypography-body1 md css-xpxqog"
                type="small"
              >
                Teacher Support
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
  </div>
</div>



<div class=" css-1oru8ap"><svg class="css-129n9a0" width="1620" height="128" viewBox="0 0 1620 128" xmlns="http://www.w3.org/2000/svg" fill="#000000ff"><path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path></svg></div>
</div>
}
</section>








</div>
       </div>
            
            
            
            
          :
        
     <div>
     <div  class="h-screen w-screen bg-gray-50 flex items-center">
         <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                 <div class="w-full lg:w-1/2 mx-8">
                     <div style={{fontSize:'40px',lineHeight:'50px'}} class="text-7xl text-blue-800 anton font-dark font-extrabold mb-8"> If you wish to upgrade your plan, you must wait until your next billing cycle.</div>
         
                   <div className=' fjalla mb-5'>
                   Next Billing Date: { (((billingDate)))}
                   
                 </div>
               
                     <Link to='/dashboard'>
                     <button style={{fontSize:'13px'}} class="mr-2 inline-flex px-5 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                   
                                   Back
                                 </button>
                     </Link>      Institute Pricing
                 <button style={{fontSize:'13px'}}onClick={billingDateErrorSet}  class="cursor-pointer px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-green-800 fjalla capitalize active:bg-green-600 hover:bg-green-700">Call Us</button>
      
                 </div>
             <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
             <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
             </div>
         
         </div>
     </div>
     
     </div>
  
    
            )
            )
        })
        :
        
        <div>
          <DashboardNav/>
          {
                  <section
              
                  className=" relative z-10 overflow-hidden bg-white pt-20 pb-12  lg:pb-[90px]"
                >
                   <h2 style={{fontSize:'3vw'}}
                            className="text-center home_description-header mb-4 text-3xl font-bold text-gray-800 sm:text-4xl md:text-[40px]"
                          >
                            Our Pricing Plan
                          </h2>
                  <div className='flex-col'>
            <ol class="flex items-center w-full  space-x-2 text-sm font-medium text-center text-gray-500  border border-gray-200 rounded-lg shadow-sm ">
            
            
            
            <li onClick={toTeacherDesktop} class=" flex items-center  text-gray-900 text-center justify-center cursor-pointer hover:bg-gray-50 pt-3 pb-3" style={{width:"50%",margin:0}}>
            
            Teachers
            
            </li>
            
            
            <li  onClick={toInstitutesDesktop}class="flex items-center justify-center  text-gray-900 text-center border-l pt-3 pb-3 cursor-pointer pt-3 pb-3 hover:bg-gray-50 " style={{width:"50%",borderBottom:"3px solid blue",margin:0}}>
            
             <p className='block text-gray-900 text-center '>Institutes</p>
            

            </li>
            </ol>
            <div className="flex justify-between">
                <div>
            
                </div>
                <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
                                <div class="sm:-mx-0.5 flex">{
                                  oneMonth == true ?
                                    <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={oneMonthTeacher}>1 Month</button>
          :
          <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={oneMonthTeacher}>1 Month</button>
          
          
                                }
          
                                {
                                  threeMonth == true ?
                                    <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={threeMonthTeacher}>3 Months</button>
          :
          <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={threeMonthTeacher}>3 Months</button>
          
          
                                }
                                                      {
                                  year == true ?
                                    <button style={{fontSize:'1vw'}} class=" focus:outline-none px-3 py-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-800 rounded-lg fjalla" onClick={yearTeacher}>Annual</button>
          :
          <button class=" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 bg-transparent rounded-lg hover:bg-gray-200 fjalla"onClick={yearTeacher}>Annual</button>
          
          
                                }
                                </div>
                            </div>
              </div>
            </div>
               {
                oneMonth == true ?
                <div className="container mx-auto">
                <div className="-mx-4 flex flex-wrap">
                  <div className="w-full px-4">
                    <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                 
                     
                
                    </div>
                  </div>
                </div>
            
                <div className="-mx-4 flex flex-wrap justify-center">
                  <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                    <div
                      className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                    >
                      <span className="mb-4 block text-lg font-semibold text-primary">
                       Basic
                      </span>
                      <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                        <span>20,000 ETB</span>
                      </h2>
                      <p
                        className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
                      >
                       
                      </p>
                      <div className="mb-7">
                        <p className="mb-1 Mooli text-base leading-loose text-body-color">
                          Unlimited Courses
                        </p>
                        <p className="mb-1 Mooli text-base leading-loose text-body-color">
                          Certification and Recognition
                        </p>
                        <p className="mb-1 Mooli text-base leading-loose text-body-color">
                          Progress Tracking and Assessment
                        </p>
                     
                        <p className="mb-1 Mooli text-base leading-loose text-body-color">
                          Teacher Support
                        </p>
                      </div>
                  <Link to={`/checkout/institute/basic/month/${uid + currentDateTimeString}`}>
                    
                  <button
                        className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
                      >
                        Choose Plan
                      </button></Link>
            
                      <div>
                        <span className="absolute right-0 top-7 z-[-1]">
                          <svg
                            width="77"
                            height="172"
                            viewBox="0 0 77 172"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                            <defs>
                              <linearGradient
                                id="paint0_linear"
                                x1="86"
                                y1="0"
                                x2="86"
                                y2="172"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#3056D3" stopOpacity="0.09" />
                                <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </span>
                        <span className="absolute right-4 top-4 z-[-1]">
                          <svg
                            width="41"
                            height="89"
                            viewBox="0 0 41 89"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="38.9138"
                              cy="87.4849"
                              r="1.42021"
                              transform="rotate(180 38.9138 87.4849)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="74.9871"
                              r="1.42021"
                              transform="rotate(180 38.9138 74.9871)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="62.4892"
                              r="1.42021"
                              transform="rotate(180 38.9138 62.4892)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="38.3457"
                              r="1.42021"
                              transform="rotate(180 38.9138 38.3457)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="13.634"
                              r="1.42021"
                              transform="rotate(180 38.9138 13.634)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="50.2754"
                              r="1.42021"
                              transform="rotate(180 38.9138 50.2754)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="26.1319"
                              r="1.42021"
                              transform="rotate(180 38.9138 26.1319)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="1.42021"
                              r="1.42021"
                              transform="rotate(180 38.9138 1.42021)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="87.4849"
                              r="1.42021"
                              transform="rotate(180 26.4157 87.4849)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="74.9871"
                              r="1.42021"
                              transform="rotate(180 26.4157 74.9871)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="62.4892"
                              r="1.42021"
                              transform="rotate(180 26.4157 62.4892)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="38.3457"
                              r="1.42021"
                              transform="rotate(180 26.4157 38.3457)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="13.634"
                              r="1.42021"
                              transform="rotate(180 26.4157 13.634)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="50.2754"
                              r="1.42021"
                              transform="rotate(180 26.4157 50.2754)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="26.1319"
                              r="1.42021"
                              transform="rotate(180 26.4157 26.1319)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="1.4202"
                              r="1.42021"
                              transform="rotate(180 26.4157 1.4202)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="87.4849"
                              r="1.42021"
                              transform="rotate(180 13.9177 87.4849)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="74.9871"
                              r="1.42021"
                              transform="rotate(180 13.9177 74.9871)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="62.4892"
                              r="1.42021"
                              transform="rotate(180 13.9177 62.4892)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="38.3457"
                              r="1.42021"
                              transform="rotate(180 13.9177 38.3457)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="13.634"
                              r="1.42021"
                              transform="rotate(180 13.9177 13.634)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="50.2754"
                              r="1.42021"
                              transform="rotate(180 13.9177 50.2754)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="26.1319"
                              r="1.42021"
                              transform="rotate(180 13.9177 26.1319)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="1.42019"
                              r="1.42021"
                              transform="rotate(180 13.9177 1.42019)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="87.4849"
                              r="1.42021"
                              transform="rotate(180 1.41963 87.4849)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="74.9871"
                              r="1.42021"
                              transform="rotate(180 1.41963 74.9871)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="62.4892"
                              r="1.42021"
                              transform="rotate(180 1.41963 62.4892)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="38.3457"
                              r="1.42021"
                              transform="rotate(180 1.41963 38.3457)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="13.634"
                              r="1.42021"
                              transform="rotate(180 1.41963 13.634)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="50.2754"
                              r="1.42021"
                              transform="rotate(180 1.41963 50.2754)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="26.1319"
                              r="1.42021"
                              transform="rotate(180 1.41963 26.1319)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="1.4202"
                              r="1.42021"
                              transform="rotate(180 1.41963 1.4202)"
                              fill="#3056D3"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
            
                  <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                    <div
                      className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                    >
                      <span className="mb-4 block text-lg font-semibold text-primary ">
                       Premium
                      </span>
                      <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
                        <span>50,000 ETB</span>
                      </h2>
                      <p
                        className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
                      >
                        
                      </p>
                      <div className="mb-7">
                        <p className="mb-1 Mooli text-base leading-loose text-body-color">
                          Unlimited Courses
                        </p>
                        <p className="mb-1 Mooli text-base leading-loose text-body-color">
                          Certification and Recognition
                        </p>
                        <p className="mb-1 Mooli text-base leading-loose text-body-color">
                          Progress Tracking and Assessment
                        </p>
                     
                        <p className="mb-1 Mooli text-base leading-loose text-body-color">
                          Teacher Support
                        </p>
                      </div>
                   <Link to={`/checkout/institute/premium/month/${uid + currentDateTimeString}`}>
                   <button
                        className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
                      >
                        Choose Plan
                      </button>
                   </Link>
            
                      <div>
                        <span className="absolute right-0 top-7 z-[-1]">
                          <svg
                            width="77"
                            height="172"
                            viewBox="0 0 77 172"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                            <defs>
                              <linearGradient
                                id="paint0_linear"
                                x1="86"
                                y1="0"
                                x2="86"
                                y2="172"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#3056D3" stopOpacity="0.09" />
                                <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </span>
                        <span className="absolute right-4 top-4 z-[-1]">
                          <svg
                            width="41"
                            height="89"
                            viewBox="0 0 41 89"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="38.9138"
                              cy="87.4849"
                              r="1.42021"
                              transform="rotate(180 38.9138 87.4849)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="74.9871"
                              r="1.42021"
                              transform="rotate(180 38.9138 74.9871)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="62.4892"
                              r="1.42021"
                              transform="rotate(180 38.9138 62.4892)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="38.3457"
                              r="1.42021"
                              transform="rotate(180 38.9138 38.3457)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="13.634"
                              r="1.42021"
                              transform="rotate(180 38.9138 13.634)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="50.2754"
                              r="1.42021"
                              transform="rotate(180 38.9138 50.2754)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="26.1319"
                              r="1.42021"
                              transform="rotate(180 38.9138 26.1319)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="38.9138"
                              cy="1.42021"
                              r="1.42021"
                              transform="rotate(180 38.9138 1.42021)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="87.4849"
                              r="1.42021"
                              transform="rotate(180 26.4157 87.4849)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="74.9871"
                              r="1.42021"
                              transform="rotate(180 26.4157 74.9871)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="62.4892"
                              r="1.42021"
                              transform="rotate(180 26.4157 62.4892)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="38.3457"
                              r="1.42021"
                              transform="rotate(180 26.4157 38.3457)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="13.634"
                              r="1.42021"
                              transform="rotate(180 26.4157 13.634)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="50.2754"
                              r="1.42021"
                              transform="rotate(180 26.4157 50.2754)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="26.1319"
                              r="1.42021"
                              transform="rotate(180 26.4157 26.1319)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="26.4157"
                              cy="1.4202"
                              r="1.42021"
                              transform="rotate(180 26.4157 1.4202)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="87.4849"
                              r="1.42021"
                              transform="rotate(180 13.9177 87.4849)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="74.9871"
                              r="1.42021"
                              transform="rotate(180 13.9177 74.9871)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="62.4892"
                              r="1.42021"
                              transform="rotate(180 13.9177 62.4892)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="38.3457"
                              r="1.42021"
                              transform="rotate(180 13.9177 38.3457)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="13.634"
                              r="1.42021"
                              transform="rotate(180 13.9177 13.634)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="50.2754"
                              r="1.42021"
                              transform="rotate(180 13.9177 50.2754)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="26.1319"
                              r="1.42021"
                              transform="rotate(180 13.9177 26.1319)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="13.9177"
                              cy="1.42019"
                              r="1.42021"
                              transform="rotate(180 13.9177 1.42019)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="87.4849"
                              r="1.42021"
                              transform="rotate(180 1.41963 87.4849)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="74.9871"
                              r="1.42021"
                              transform="rotate(180 1.41963 74.9871)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="62.4892"
                              r="1.42021"
                              transform="rotate(180 1.41963 62.4892)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="38.3457"
                              r="1.42021"
                              transform="rotate(180 1.41963 38.3457)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="13.634"
                              r="1.42021"
                              transform="rotate(180 1.41963 13.634)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="50.2754"
                              r="1.42021"
                              transform="rotate(180 1.41963 50.2754)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="26.1319"
                              r="1.42021"
                              transform="rotate(180 1.41963 26.1319)"
                              fill="#3056D3"
                            />
                            <circle
                              cx="1.41963"
                              cy="1.4202"
                              r="1.42021"
                              transform="rotate(180 1.41963 1.4202)"
                              fill="#3056D3"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              threeMonth == true ?
              <div className="container mx-auto">
              <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4">
                  <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
               
                   
              
                  </div>
                </div>
              </div>
          
              <div className="-mx-4 flex flex-wrap justify-center">
                <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                  >
                    <span className="mb-4 block text-lg font-semibold text-primary">
                      Basic
                    </span>
                    <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                      <span>30,000 ETB</span>
                    </h2>
                    <p
                      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
                    >
                     
                    </p>
                    <div className="mb-7">
                      <p className="mb-1 Mooli text-base leading-loose text-body-color">
                        Unlimited Courses
                      </p>
                      <p className="mb-1 Mooli text-base leading-loose text-body-color">
                        Certification and Recognition
                      </p>
                      <p className="mb-1 Mooli text-base leading-loose text-body-color">
                        Progress Tracking and Assessment
                      </p>
                   
                      <p className="mb-1 Mooli text-base leading-loose text-body-color">
                        Teacher Support
                      </p>
                    </div>
                <Link to="/checkout/basic/3months">
                <button
                      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
                    >
                      Choose Plan
                    </button></Link>
          
                    <div>
                      <span className="absolute right-0 top-7 z-[-1]">
                        <svg
                          width="77"
                          height="172"
                          viewBox="0 0 77 172"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                          <defs>
                            <linearGradient
                              id="paint0_linear"
                              x1="86"
                              y1="0"
                              x2="86"
                              y2="172"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#3056D3" stopOpacity="0.09" />
                              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className="absolute right-4 top-4 z-[-1]">
                        <svg
                          width="41"
                          height="89"
                          viewBox="0 0 41 89"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="38.9138"
                            cy="87.4849"
                            r="1.42021"
                            transform="rotate(180 38.9138 87.4849)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="74.9871"
                            r="1.42021"
                            transform="rotate(180 38.9138 74.9871)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="62.4892"
                            r="1.42021"
                            transform="rotate(180 38.9138 62.4892)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="38.3457"
                            r="1.42021"
                            transform="rotate(180 38.9138 38.3457)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="13.634"
                            r="1.42021"
                            transform="rotate(180 38.9138 13.634)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="50.2754"
                            r="1.42021"
                            transform="rotate(180 38.9138 50.2754)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="26.1319"
                            r="1.42021"
                            transform="rotate(180 38.9138 26.1319)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="1.42021"
                            r="1.42021"
                            transform="rotate(180 38.9138 1.42021)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="87.4849"
                            r="1.42021"
                            transform="rotate(180 26.4157 87.4849)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="74.9871"
                            r="1.42021"
                            transform="rotate(180 26.4157 74.9871)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="62.4892"
                            r="1.42021"
                            transform="rotate(180 26.4157 62.4892)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="38.3457"
                            r="1.42021"
                            transform="rotate(180 26.4157 38.3457)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="13.634"
                            r="1.42021"
                            transform="rotate(180 26.4157 13.634)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="50.2754"
                            r="1.42021"
                            transform="rotate(180 26.4157 50.2754)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="26.1319"
                            r="1.42021"
                            transform="rotate(180 26.4157 26.1319)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="1.4202"
                            r="1.42021"
                            transform="rotate(180 26.4157 1.4202)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="87.4849"
                            r="1.42021"
                            transform="rotate(180 13.9177 87.4849)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="74.9871"
                            r="1.42021"
                            transform="rotate(180 13.9177 74.9871)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="62.4892"
                            r="1.42021"
                            transform="rotate(180 13.9177 62.4892)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="38.3457"
                            r="1.42021"
                            transform="rotate(180 13.9177 38.3457)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="13.634"
                            r="1.42021"
                            transform="rotate(180 13.9177 13.634)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="50.2754"
                            r="1.42021"
                            transform="rotate(180 13.9177 50.2754)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="26.1319"
                            r="1.42021"
                            transform="rotate(180 13.9177 26.1319)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="1.42019"
                            r="1.42021"
                            transform="rotate(180 13.9177 1.42019)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="87.4849"
                            r="1.42021"
                            transform="rotate(180 1.41963 87.4849)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="74.9871"
                            r="1.42021"
                            transform="rotate(180 1.41963 74.9871)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="62.4892"
                            r="1.42021"
                            transform="rotate(180 1.41963 62.4892)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="38.3457"
                            r="1.42021"
                            transform="rotate(180 1.41963 38.3457)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="13.634"
                            r="1.42021"
                            transform="rotate(180 1.41963 13.634)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="50.2754"
                            r="1.42021"
                            transform="rotate(180 1.41963 50.2754)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="26.1319"
                            r="1.42021"
                            transform="rotate(180 1.41963 26.1319)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="1.4202"
                            r="1.42021"
                            transform="rotate(180 1.41963 1.4202)"
                            fill="#3056D3"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
          
                <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div
                    className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                  >
                    <span className="mb-4 block text-lg font-semibold text-primary">
                      Premium
                    </span>
                    <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
                      <span>75,000 ETB</span>
                    </h2>
                    <p
                      className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
                    >
                      
                    </p>
                    <div className="mb-7">
                      <p className="mb-1 Mooli text-base leading-loose text-body-color">
                        Unlimited Courses
                      </p>
                      <p className="mb-1 Mooli text-base leading-loose text-body-color">
                        Certification and Recognition
                      </p>
                      <p className="mb-1 Mooli text-base leading-loose text-body-color">
                        Progress Tracking and Assessment
                      </p>
                   
                      <p className="mb-1 Mooli text-base leading-loose text-body-color">
                        Teacher Support
                      </p>
                    </div>
                <Link to="/checkout/premium/3months">
         
                 <button
                      className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
                    >
                      Choose Plan
                    </button>
                 </Link>
          
                    <div>
                      <span className="absolute right-0 top-7 z-[-1]">
                        <svg
                          width="77"
                          height="172"
                          viewBox="0 0 77 172"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                          <defs>
                            <linearGradient
                              id="paint0_linear"
                              x1="86"
                              y1="0"
                              x2="86"
                              y2="172"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#3056D3" stopOpacity="0.09" />
                              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className="absolute right-4 top-4 z-[-1]">
                        <svg
                          width="41"
                          height="89"
                          viewBox="0 0 41 89"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="38.9138"
                            cy="87.4849"
                            r="1.42021"
                            transform="rotate(180 38.9138 87.4849)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="74.9871"
                            r="1.42021"
                            transform="rotate(180 38.9138 74.9871)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="62.4892"
                            r="1.42021"
                            transform="rotate(180 38.9138 62.4892)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="38.3457"
                            r="1.42021"
                            transform="rotate(180 38.9138 38.3457)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="13.634"
                            r="1.42021"
                            transform="rotate(180 38.9138 13.634)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="50.2754"
                            r="1.42021"
                            transform="rotate(180 38.9138 50.2754)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="26.1319"
                            r="1.42021"
                            transform="rotate(180 38.9138 26.1319)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="38.9138"
                            cy="1.42021"
                            r="1.42021"
                            transform="rotate(180 38.9138 1.42021)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="87.4849"
                            r="1.42021"
                            transform="rotate(180 26.4157 87.4849)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="74.9871"
                            r="1.42021"
                            transform="rotate(180 26.4157 74.9871)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="62.4892"
                            r="1.42021"
                            transform="rotate(180 26.4157 62.4892)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="38.3457"
                            r="1.42021"
                            transform="rotate(180 26.4157 38.3457)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="13.634"
                            r="1.42021"
                            transform="rotate(180 26.4157 13.634)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="50.2754"
                            r="1.42021"
                            transform="rotate(180 26.4157 50.2754)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="26.1319"
                            r="1.42021"
                            transform="rotate(180 26.4157 26.1319)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="26.4157"
                            cy="1.4202"
                            r="1.42021"
                            transform="rotate(180 26.4157 1.4202)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="87.4849"
                            r="1.42021"
                            transform="rotate(180 13.9177 87.4849)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="74.9871"
                            r="1.42021"
                            transform="rotate(180 13.9177 74.9871)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="62.4892"
                            r="1.42021"
                            transform="rotate(180 13.9177 62.4892)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="38.3457"
                            r="1.42021"
                            transform="rotate(180 13.9177 38.3457)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="13.634"
                            r="1.42021"
                            transform="rotate(180 13.9177 13.634)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="50.2754"
                            r="1.42021"
                            transform="rotate(180 13.9177 50.2754)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="26.1319"
                            r="1.42021"
                            transform="rotate(180 13.9177 26.1319)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="13.9177"
                            cy="1.42019"
                            r="1.42021"
                            transform="rotate(180 13.9177 1.42019)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="87.4849"
                            r="1.42021"
                            transform="rotate(180 1.41963 87.4849)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="74.9871"
                            r="1.42021"
                            transform="rotate(180 1.41963 74.9871)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="62.4892"
                            r="1.42021"
                            transform="rotate(180 1.41963 62.4892)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="38.3457"
                            r="1.42021"
                            transform="rotate(180 1.41963 38.3457)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="13.634"
                            r="1.42021"
                            transform="rotate(180 1.41963 13.634)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="50.2754"
                            r="1.42021"
                            transform="rotate(180 1.41963 50.2754)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="26.1319"
                            r="1.42021"
                            transform="rotate(180 1.41963 26.1319)"
                            fill="#3056D3"
                          />
                          <circle
                            cx="1.41963"
                            cy="1.4202"
                            r="1.42021"
                            transform="rotate(180 1.41963 1.4202)"
                            fill="#3056D3"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="container mx-auto">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4">
                <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
             
                 
            
                </div>
              </div>
            </div>
          
            <div className="-mx-4 flex flex-wrap justify-center">
              <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                <div
                  className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                >
                  <span className="mb-4 block text-lg font-semibold text-primary">
                    Basic
                  </span>
                  <h2 className="mb-5 text-[30px] font-bold text-dark home_description-header" style={{fontSize:"40px"}}>
                    <span>60,000 ETB</span>
                  </h2>
                  <p
                    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
                  >
                   
                  </p>
                  <div className="mb-7">
                    <p className="mb-1 Mooli text-base leading-loose text-body-color">
                      Unlimited Courses
                    </p>
                    <p className="mb-1 Mooli text-base leading-loose text-body-color">
                      Certification and Recognition
                    </p>
                    <p className="mb-1 Mooli text-base leading-loose text-body-color">
                      Progress Tracking and Assessment
                    </p>
                 
                    <p className="mb-1 Mooli text-base leading-loose text-body-color">
                      Teacher Support
                    </p>
                  </div>
           
              <Link to="/checkout/basic/annual">
                
              <button
                    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition Mooli hover:border-blue-800 hover:bg-blue-800 hover:text-white"
                  >
                    Choose Plan
                  </button></Link>
          
                  <div>
                    <span className="absolute right-0 top-7 z-[-1]">
                      <svg
                        width="77"
                        height="172"
                        viewBox="0 0 77 172"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                        <defs>
                          <linearGradient
                            id="paint0_linear"
                            x1="86"
                            y1="0"
                            x2="86"
                            y2="172"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#3056D3" stopOpacity="0.09" />
                            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute right-4 top-4 z-[-1]">
                      <svg
                        width="41"
                        height="89"
                        viewBox="0 0 41 89"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="38.9138"
                          cy="87.4849"
                          r="1.42021"
                          transform="rotate(180 38.9138 87.4849)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="74.9871"
                          r="1.42021"
                          transform="rotate(180 38.9138 74.9871)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="62.4892"
                          r="1.42021"
                          transform="rotate(180 38.9138 62.4892)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="38.3457"
                          r="1.42021"
                          transform="rotate(180 38.9138 38.3457)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="13.634"
                          r="1.42021"
                          transform="rotate(180 38.9138 13.634)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="50.2754"
                          r="1.42021"
                          transform="rotate(180 38.9138 50.2754)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="26.1319"
                          r="1.42021"
                          transform="rotate(180 38.9138 26.1319)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="1.42021"
                          r="1.42021"
                          transform="rotate(180 38.9138 1.42021)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="87.4849"
                          r="1.42021"
                          transform="rotate(180 26.4157 87.4849)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="74.9871"
                          r="1.42021"
                          transform="rotate(180 26.4157 74.9871)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="62.4892"
                          r="1.42021"
                          transform="rotate(180 26.4157 62.4892)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="38.3457"
                          r="1.42021"
                          transform="rotate(180 26.4157 38.3457)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="13.634"
                          r="1.42021"
                          transform="rotate(180 26.4157 13.634)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="50.2754"
                          r="1.42021"
                          transform="rotate(180 26.4157 50.2754)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="26.1319"
                          r="1.42021"
                          transform="rotate(180 26.4157 26.1319)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="1.4202"
                          r="1.42021"
                          transform="rotate(180 26.4157 1.4202)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="87.4849"
                          r="1.42021"
                          transform="rotate(180 13.9177 87.4849)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="74.9871"
                          r="1.42021"
                          transform="rotate(180 13.9177 74.9871)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="62.4892"
                          r="1.42021"
                          transform="rotate(180 13.9177 62.4892)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="38.3457"
                          r="1.42021"
                          transform="rotate(180 13.9177 38.3457)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="13.634"
                          r="1.42021"
                          transform="rotate(180 13.9177 13.634)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="50.2754"
                          r="1.42021"
                          transform="rotate(180 13.9177 50.2754)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="26.1319"
                          r="1.42021"
                          transform="rotate(180 13.9177 26.1319)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="1.42019"
                          r="1.42021"
                          transform="rotate(180 13.9177 1.42019)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="87.4849"
                          r="1.42021"
                          transform="rotate(180 1.41963 87.4849)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="74.9871"
                          r="1.42021"
                          transform="rotate(180 1.41963 74.9871)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="62.4892"
                          r="1.42021"
                          transform="rotate(180 1.41963 62.4892)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="38.3457"
                          r="1.42021"
                          transform="rotate(180 1.41963 38.3457)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="13.634"
                          r="1.42021"
                          transform="rotate(180 1.41963 13.634)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="50.2754"
                          r="1.42021"
                          transform="rotate(180 1.41963 50.2754)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="26.1319"
                          r="1.42021"
                          transform="rotate(180 1.41963 26.1319)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="1.4202"
                          r="1.42021"
                          transform="rotate(180 1.41963 1.4202)"
                          fill="#3056D3"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
          
              <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                <div
                  className="relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
                >
                  <span className="mb-4 block text-lg font-semibold text-primary">
                    Premium
                  </span>
                  <h2 className="mb-5 text-[42px] font-bold text-dark home_description-header" style={{fontSize:'40px'}}>
                    <span>150,000 ETB</span>
                  </h2>
                  <p
                    className="mb-8 border-b border-[#F2F2F2] pb-8 text-base text-body-color"
                  >
                    
                  </p>
                  <div className="mb-7">
                    <p className="mb-1 Mooli text-base leading-loose text-body-color">
                      Unlimited Courses
                    </p>
                    <p className="mb-1 Mooli text-base leading-loose text-body-color">
                      Certification and Recognition
                    </p>
                    <p className="mb-1 Mooli text-base leading-loose text-body-color">
                      Progress Tracking and Assessment
                    </p>
                 
                    <p className="mb-1 Mooli text-base leading-loose text-body-color">
                      Teacher Support
                    </p>
                  </div>
              <Link to="/checkout/premium/annual">
         
               <button
                    className="block w-full rounded-md border border-[#D4DEFF] bg-transparent p-4 text-center text-base font-semibold text-primary transition hover:border-blue-800 hover:bg-blue-800 hover:text-white"
                  >
                    Choose Plan
                  </button>
               </Link>
          
                  <div>
                    <span className="absolute right-0 top-7 z-[-1]">
                      <svg
                        width="77"
                        height="172"
                        viewBox="0 0 77 172"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                        <defs>
                          <linearGradient
                            id="paint0_linear"
                            x1="86"
                            y1="0"
                            x2="86"
                            y2="172"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#3056D3" stopOpacity="0.09" />
                            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute right-4 top-4 z-[-1]">
                      <svg
                        width="41"
                        height="89"
                        viewBox="0 0 41 89"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="38.9138"
                          cy="87.4849"
                          r="1.42021"
                          transform="rotate(180 38.9138 87.4849)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="74.9871"
                          r="1.42021"
                          transform="rotate(180 38.9138 74.9871)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="62.4892"
                          r="1.42021"
                          transform="rotate(180 38.9138 62.4892)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="38.3457"
                          r="1.42021"
                          transform="rotate(180 38.9138 38.3457)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="13.634"
                          r="1.42021"
                          transform="rotate(180 38.9138 13.634)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="50.2754"
                          r="1.42021"
                          transform="rotate(180 38.9138 50.2754)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="26.1319"
                          r="1.42021"
                          transform="rotate(180 38.9138 26.1319)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="38.9138"
                          cy="1.42021"
                          r="1.42021"
                          transform="rotate(180 38.9138 1.42021)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="87.4849"
                          r="1.42021"
                          transform="rotate(180 26.4157 87.4849)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="74.9871"
                          r="1.42021"
                          transform="rotate(180 26.4157 74.9871)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="62.4892"
                          r="1.42021"
                          transform="rotate(180 26.4157 62.4892)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="38.3457"
                          r="1.42021"
                          transform="rotate(180 26.4157 38.3457)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="13.634"
                          r="1.42021"
                          transform="rotate(180 26.4157 13.634)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="50.2754"
                          r="1.42021"
                          transform="rotate(180 26.4157 50.2754)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="26.1319"
                          r="1.42021"
                          transform="rotate(180 26.4157 26.1319)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="26.4157"
                          cy="1.4202"
                          r="1.42021"
                          transform="rotate(180 26.4157 1.4202)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="87.4849"
                          r="1.42021"
                          transform="rotate(180 13.9177 87.4849)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="74.9871"
                          r="1.42021"
                          transform="rotate(180 13.9177 74.9871)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="62.4892"
                          r="1.42021"
                          transform="rotate(180 13.9177 62.4892)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="38.3457"
                          r="1.42021"
                          transform="rotate(180 13.9177 38.3457)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="13.634"
                          r="1.42021"
                          transform="rotate(180 13.9177 13.634)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="50.2754"
                          r="1.42021"
                          transform="rotate(180 13.9177 50.2754)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="26.1319"
                          r="1.42021"
                          transform="rotate(180 13.9177 26.1319)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="13.9177"
                          cy="1.42019"
                          r="1.42021"
                          transform="rotate(180 13.9177 1.42019)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="87.4849"
                          r="1.42021"
                          transform="rotate(180 1.41963 87.4849)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="74.9871"
                          r="1.42021"
                          transform="rotate(180 1.41963 74.9871)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="62.4892"
                          r="1.42021"
                          transform="rotate(180 1.41963 62.4892)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="38.3457"
                          r="1.42021"
                          transform="rotate(180 1.41963 38.3457)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="13.634"
                          r="1.42021"
                          transform="rotate(180 1.41963 13.634)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="50.2754"
                          r="1.42021"
                          transform="rotate(180 1.41963 50.2754)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="26.1319"
                          r="1.42021"
                          transform="rotate(180 1.41963 26.1319)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.41963"
                          cy="1.4202"
                          r="1.42021"
                          transform="rotate(180 1.41963 1.4202)"
                          fill="#3056D3"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
               }
                </section>
            
            
          
      
          }
        </div>
       }
     
     

   

 

  
  </div>
    </div>
    )

  
}
