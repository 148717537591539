import { collection, doc, onSnapshot, orderBy, query,deleteDoc,setDoc } from 'firebase/firestore';

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase'
import { onAuthStateChanged,signOut } from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import Nav from './Nav'
import moment from 'moment'
import DashboardNav from '../dashboard/dashboardNav';
export default function Checkout() {
    const [users,setuser] = useState([])
    const [isloading,setisloading] = useState(false)
    const [cbebirr,setcbebirr] = useState(false)
  const [spinner,setspinner] =  useState(true)
  const [telebirr,settelebirr] = useState(false)
  const [notification,setnotification] = useState([])
  const [pop,setpop] =  useState(false)
  const [notificationPop,setnotificationPop] =  useState(false)

    const [boa,setboa] = useState(false)
    const [cbe,setcbe] = useState(false)
    const [progress,setprogress] = useState('')
    const [chosenMethod,setchosenMethod] = useState("")
    const [instructorId,setinstructorId] = useState("")

    const navigate = useNavigate()

    const [screenshot,setscreenshot] = useState([])
    const [transaction,settransaction] = useState([])

    const [screenshotError,setscreenshotError] = useState("")

    const [fullName,setFullName] = useState("")
    const [fullNameError,setfullNameError] = useState("")
    const [transferredFrom,setTransferredFrom] = useState("")
    const [transferredFromError,setTransferredFromError] = useState("")

    const [fileMesssage,setfileMesssage] = useState("")
    const [selectedLecture,setSelectedLecture] = useState([])

    const [uid,setuid] = useState("")
    const [accountType,setaccountType] = useState("")

    const [courseTitle,setCourseTitle] = useState("")
    const [coursePrice,setCoursePrice] = useState("")
    const [error,seterror] = useState("")
    const currentTime = moment();
const currentMoment = moment(currentTime, 'YYYY-MM-DD HH:mm:ss');
const currentYear = currentMoment.year();
const currentMonth = currentMoment.month();
const currentDay = currentMoment.date();
const currentHour = currentMoment.hour();
const currentMinute = currentMoment.minute();
const currentSecond = currentMoment.second();




let currentDateTime = moment([currentYear, currentMonth, currentDay, currentHour, currentMinute, currentSecond]);

    const {checkoutId,plan,user,length} = useParams()
    

  const amount = (plan == 'basic' && length == 'month' && accountType == 'Institute' ? '20,000':
  plan == 'basic' && length == '3months' && accountType == 'Institute' ? '30,000' :
  plan == 'basic' && length == 'annual' && accountType == 'Institute' ?'60,000' :
  plan == 'premium' && length == 'month' && accountType == 'Institute' ? '50,000':
  plan == 'premium' && length == '3months' && accountType == 'Institute' ? '75,000':
  plan == 'premium' && length == 'annual' && accountType == 'Institute' && '150,000'
  
  
  
  )

  function handleLogOut(){
    signOut(auth).then(
     navigate('/')
    )
   }

   function desktopSpinnerHandler(){
    setspinner(false)
   }

  function getCurrentDateTimeString() {
    const currentDate = new Date();
    return currentDate.toLocaleString('en-GB', {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })
    .replace(/,/g, '')
    .replace(/\s/g, '');
  }
  const currentDateTimeString = getCurrentDateTimeString();


    const [screenshotHandler,setscreenshotHandler] = useState([])






   
      



      function fullNameSet(e) {
        setFullName(e.target.value)
        seterror('')
        if(e.target.value.length == 0){
            setfullNameError("error")
        }
        else{
            setfullNameError("")
        }
      }
      
    function transferredFromSet(e) {
        setTransferredFrom(e.target.value)
        seterror('')

        if(e.target.value.length == 0){
            setTransferredFromError("error")
        }
        else{
            setTransferredFromError("")
        }
      }

      const popProfile = () =>{
        if(pop == true){
          setpop(false)
        setnotificationPop(false)
    
    
        }
        else{
        setpop(true)
        setnotificationPop(false)
    
    
    
        }
    }
    const convertTimestampToDate = (timestamp) => {
      const date = timestamp.toDate();
      const currentDate = new Date();
      const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
    
      const secondsInMinute = 60;
      const secondsInHour = 3600;
      const secondsInDay = 86400;
      const secondsInWeek = 604800;
      const secondsInMonth = 2592000;
      const secondsInYear = 31536000;
    
      let relativeTime = '';
    
      if (timeDifferenceInSeconds < secondsInMinute) {
        relativeTime = `${timeDifferenceInSeconds}s ago`;
      } else if (timeDifferenceInSeconds < secondsInHour) {
        const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
        relativeTime = `${minutes}m ago`;
      } else if (timeDifferenceInSeconds < secondsInDay) {
        const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
        relativeTime = `${hours}h ago`;
      } else if (timeDifferenceInSeconds < secondsInWeek) {
        const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
        relativeTime = `${days}d ago`;
      } else if (timeDifferenceInSeconds < secondsInMonth) {
        const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
        relativeTime = `${weeks}w ago`;
      } else if (timeDifferenceInSeconds < secondsInYear) {
        const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
        relativeTime = `${months}mo ago`;
      } else {
        const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
        relativeTime = `${years}y ago`;
      }
    
      return relativeTime;
    };
    const popNotification = () =>{
      if(notificationPop == true){
        setnotificationPop(false)
        setpop(false)
      }
      else{
      setnotificationPop(true)
      setpop(false)
    
    
    
      }
    }
       function removeNotification(id){
        const notificationRef = doc(firestore, "AccountDetails", uid, "Notification", id);
    
        setDoc(notificationRef,{
          seen:true
        },{merge:true})
        .then(() => {
          
          setnotification(prevArray => prevArray.filter(item => item.id !== id));
        })
        .catch((error) => {
        });
    
    
       }
      
 

      function handleImgSelect(event) {
        const selectedFiles = event.target.files;
      
        // Check if a file is selected
        if (selectedFiles.length > 0) {
          const selectedFile = selectedFiles[0];
      
          // Check file type
          if (selectedFile.type.startsWith('image/')) {
            // Check file size
            const maxSize = 10 * 1024 * 1024; // 10MB
            if (selectedFile.size > maxSize) {
              setfileMesssage(`File ${selectedFile.name} exceeds the size limit of 10MB.`);
              return false;
            } else {
              setfileMesssage('');
              setscreenshot(selectedFile);
            }
          } else {
            setfileMesssage(`File ${selectedFile.name} is not a valid image file.`);
            return false;
          }
        } else {
          setfileMesssage('');
          setscreenshot(null);
        }
      
       setscreenshotError('');
      
        // Clear the file input value to allow selecting the same file gain
        event.target.value = '';
      }
        
        const minimizeUploadImg = ()=>{
          const upload = document.getElementById("uploadFileDesktop")
          upload.style.display="none"
          setscreenshot([])
        }

        function backImageDesktop (){
            const upload = document.getElementById("uploadImageDesktop")
            upload.style.display="none"
            setscreenshot([])
          }
       

          function openImageDesktop (){
            const upload = document.getElementById("uploadImageDesktop")
            upload.style.display="flex"
          
          }

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
           
      
      setuid(uid)
    
   
            
      const userRef = doc(firestore,"TotalUsers",uid);
      const transactionRef = doc(firestore,"Transactions",checkoutId);
      const  sortedNotificationRef= collection(firestore, "AccountDetails", uid, "Notification");
    
      const notificationRef = query(sortedNotificationRef, orderBy("time","desc"));

      onSnapshot(notificationRef,snapshot=>{
        let main =[]
        
    
    
    
        snapshot.docs.forEach(doc=>{
          main.push({...doc.data(),id:doc.id})
         
        setnotification(main)
        })
    
        
        
      
    
      })
      onSnapshot(userRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      setuser(main)
      setaccountType(snapshot.data().accountType)
    
        
        
      
    
      })

    
     
          
      onSnapshot(transactionRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      settransaction(main)
    
        
        
      
    
      })
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);


      function handleCbeBirrDesktop(){
        setchosenMethod('Cbe Birr')

        if(cbebirr == true){
            setcbebirr(false)
            setscreenshotHandler([])

        }
        else {
            setcbebirr(true)
            setboa(false)
            settelebirr(false)
            setcbe(false)
            setscreenshotHandler([])
        }
      }

      function handleTelebirrDesktop(){
        setchosenMethod('Telebirr')

        if(telebirr == true){
            settelebirr(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)
            setscreenshotHandler([])

            setboa(false)
            settelebirr(true)
            setcbe(false)
        }
      }

    let notificationFilter = notification.filter(i=>i.seen === false).slice(0,5)

      function handleCbeDesktop(){
        setchosenMethod('Commercial Bank Of Ethiopia')

        if(cbe == true){
            setcbe(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)

            setscreenshotHandler([])

            setboa(false)
            settelebirr(false)
            setcbe(true)
        }
      }
      function handleBoaDesktop(){
        setchosenMethod('Bank Of Abyssinia')

        if(boa == true){
            setboa(false)
            setscreenshotHandler([])


        }
        else {
            setcbebirr(false)

            setscreenshotHandler([])

            setboa(true)
            settelebirr(false)
            setcbe(false)
        }
      }




      function handlePaidDesktop(checkoutId){


        const documentRef3 = doc(firestore,"AccountDetails",uid,"Transaction",checkoutId)
        const documentRef5 = doc(firestore,"Transactions",checkoutId)
        const documentRef6 = doc(firestore,"TotalUsers",uid)
        const documentRef7 = doc(firestore,"TransactionNewUsers",uid)




        const storage = getStorage()
        const reff = ref(storage, `resume/${screenshot.name}`)
        const uploadImage= uploadBytesResumable(reff,screenshot)
            
      
        if(fullName.length == 0){
            setfullNameError("error")
            seterror('error')
        }
        else{

          if(transferredFrom.length == 0){
            setTransferredFromError("error")
            seterror('error')
        }

        else{
          if(screenshot.length == 0){
            setscreenshotError("error")
            seterror('error')
        }

        else{
            setisloading(true)

         
          uploadImage.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setprogress(progress)
        
          }, 
          (error) => {
            console.log(error)
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          
        
          getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
          
              const transactionData = {
                  screenshot:downloadURL,
                  paid:false,
                  isPending:true,
                createdTime:currentDateTime.format('YYYY-MM-DD HH:mm:ss'),
                accountHolder:fullName,
                accountNumber:transferredFrom,
                transactionID:checkoutId,
                chosenMethod:chosenMethod,
                uid:uid,
                paymentFor: plan,
                amount:amount
,

                plan:plan,
                length:length,

                state:'Pending'
              

        
        
                };
              
                 setDoc(documentRef3, transactionData, { merge: true });
                 setDoc(documentRef6, {
                    isPending:true,
                 }, { merge: true });
                 
                 setDoc(documentRef5, transactionData, { merge: true }).then(
                 setDoc(documentRef7, transactionData, { merge: true }).then(
                    setisloading(false)

                 )

                 )



        
            
          })
             
            
              
          }
        
          // )}
         
        )


         
        }
        }
        
         
        }
       
  
      }
  
  return (
    <div>
       {
        users.map(i=>{
          const {accountType,isPending,paid,state} = i
          return(
            (accountType =="Institute") && checkoutId.slice(0,checkoutId.length - 8) == uid + currentDateTimeString ?
  <div >
<div  >
    

            <body>
    
          <div>
            {
              isPending === true?
              <div>

                <div id='desktop' >
    <nav className='flex justify-between w-full  px-5 navDesktop'>

   <Link to="/dashboard" className=' bg-blue-800 ml-5' style={{width:"8%",objectFit:"cover"}}> 
                        <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                          </Link>

                <div>

   </div>


    </nav>


</div>
                <div>
          {
            transaction && transaction.map(i=>{
              const {chosenMethod,amount} = i
              return(
            <div>
                  <div id='desktop' class="h-screen   flex items-center">
                <div class=" flex flex-col md:flex-row items-center justify-between  text-gray-700">
                        <div class="w-full px-6 " style={{width:'50%'}}>
                            <div style={{fontSize:'30px',lineHeight:'30px'}} class=" text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                            <p className='fjalla flex flex-col mt-4' style={{fontSize:'15px',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                            </div>
                       
                            <Link to={`/dashboard`}>
                            <button style={{fontSize:'11px'}}  class="px-5 py-2 capitalize inline-flex  py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                          
                            Check out my account until the payment is verified
                                        </button>
                            </Link>      
             
                        </div>
                    <div class="" style={{width:'50%'}}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Code%20review-bro.svg?alt=media&token=0930164d-3285-45d1-93c0-ccf400984fc8" class="" alt="Page not found"/>
                    </div>
                
                </div>
            </div>
            <div id='mobile' class="h-screen flex-col   flex items-center">
            <nav className='flex justify-between navMobile border-b w-full '>

            <Link to="/"  style={{width:'21%'}}>

            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 

                          </Link>

<div className='flex justify-end items-center' style={{width:'30%'}}>

<div></div>
</div>
    </nav>
                <div class="  flex-col md:flex-row items-center justify-between  text-gray-700">
                        <div class="w-full flex flex-col items-center justify-center m-auto " style={{width:'90%',marginTop:'30%'}}>
                            <div style={{fontSize:'8vw',lineHeight:'8.5vw'}} class=" text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                            </div>
                            <p className='fjalla flex flex-col ' style={{fontSize:'3.5vw',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                       
                            <Link to={`/dashboard`}>
                            <button style={{padding:'2vw 5vw',marginTop:'3vw',fontSize:'3vw'}}  class=" capitalize inline-flex  py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                          
                            Check out my account until the payment is verified
                                        </button>
                            </Link>      
             
                        </div>
           
                
                </div>
            </div>

            <div id='tablet' class="h-screen flex-col   flex items-center">
            <nav className='flex justify-between navTablet w-full  pt-5'>

            <Link to="/"  style={{width:'20%'}}>
            <img style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                      
                          </Link>

<div className='flex justify-end' style={{width:'30%'}}>

</div>
    </nav>
                <div class="  flex-col md:flex-row items-center justify-between  text-gray-700">
                        <div class="w-full flex flex-col items-center justify-center m-auto " style={{width:'90%',marginTop:'20%'}}>
                            <div style={{fontSize:'6vw',lineHeight:'6.5vw'}} class=" text-gray-800 anton font-dark font-extrabold mb-8"> The transaction is currently being reviewed; you will have full access to your account once the verification process is complete
                            </div>
                            <p className='fjalla flex flex-col ' style={{fontSize:'3vw',}}>Make sure {amount} has been transferred to {chosenMethod == 'Telebirr' ? '0923580987' : chosenMethod  == 'Cbe Birr' ? '0923580987' : chosenMethod == 'Boa' ? '84942023': '100048928429834'  } via {chosenMethod}</p>
                       
                            <Link to={`/dashboard`}>
                            <button style={{padding:'2vw 5vw',marginTop:'3vw',fontSize:'2.5vw'}}  class=" capitalize inline-flex  py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
                                          
                            Check out my account until the payment is verified
                                        </button>
                            </Link>      
             
                        </div>
           
                
                </div>
            </div>
            </div>
              )
            })
          }
              </div>
              </div>
              :

              plan != 'basic' & plan != 'premium'  ?
              <div class="h-screen  flex items-center">
              <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                      <div class="w-full lg:w-1/2 mx-8">
                          <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
                      <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
                          Sorry we couldn't find the page you're looking for
                      </p>
                      <Link to='/pricing'>
                      <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>
          
                      </Link>
              </div>
                  <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
                  <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
                  </div>
              
              </div>
          </div>
              :
              length != 'month' & length != '3months' & length != 'annual'?
              <div class="h-screen  flex items-center">
              <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
                      <div class="w-full lg:w-1/2 mx-8">
                          <div class="text-7xl text-blue-800 font-dark font-extrabold mb-8"> 404</div>
                      <p class="text-2xl md:text-3xl font-light leading-normal mb-8">
                          Sorry we couldn't find the page you're looking for
                      </p>
                      <Link to='/pricing'>
                      <a  class="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-blue-800 active:bg-blue-900 hover:bg-blue-900">Back to homepage</a>
          
                      </Link>
              </div>
                  <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
                  <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found"/>
                  </div>
              
              </div>
          </div>
          :
              <div>
              {
                isloading == true ?
            <div>
                  <div id='desktop' className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                <div class="relative">
                <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>
            <div class="w-full bg-neutral-200 mt-3">
      <div
        class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
        style={{ width: progress + '%' }}
      >
        {Math.round(progress)} %
      </div>
    </div>
    <p className='fjalla mt-7'>Uploading</p>

                  </div>
                  <div id='mobile' className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                <div class="relative">
                <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>
            <div class="w-full bg-neutral-200 mt-3">
      <div
        class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
        style={{ width: progress + '%' }}
      >
        {Math.round(progress)} %
      </div>
    </div>
    <p className='fjalla mt-7'>Uploading</p>

                  </div>
                  <div id='tablet' className='flex flex-col  items-center justify-center' style={{height:'100vh'}}>
                <div class="relative">
                <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
                <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>
            <div class="w-full bg-neutral-200 mt-3">
      <div
        class={`${progress == 0 && 'hidden'} ${progress != 0 && ' bg-blue-800 p-0.5 text-center text-xs font-medium leading-none text-white'}`}
        style={{ width: progress + '%' }}
      >
        {Math.round(progress)} %
      </div>
    </div>
    <p className='fjalla mt-7'>Uploading</p>

                  </div>
                  
            </div>
                  :
               
                  state == 'Approved' ?
                  navigate('/dashboard') 
                  :
                  <div>
                  <div id='desktop' class=" ">
                     
                  <div class="px-5">
                    
                      <div class="mb-2 flex w-full justify-between">
                     
                      <h1 class=" font-bold text-gray-900 anton ml-5 mt-3" style={{fontSize:'30px'}}>Checkout for kesht Basic</h1>
                      </div>
                      <div class="mb-5 text-gray-400 flex ">
                      </div>
                  </div>
                  <p className='fjalla text-green-800 w-full bg-green-50 py-2 text-center' style={{fontSize:'11px'}}>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
                  {
              error == "error" &&
              <div id="alert-border-2" class="flex w-full text-left items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50  " role="alert">
              <svg  class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <p class=" Mooli mr-1" style={{fontSize:'11px'}}>
                   Please make sure all fields are filled out correctly
             
              </p>
             
             </div>
             
     
            }
         
                  <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                      <div class="w-full">
                          <div class="flex justify-between items-center w-full">
                              <div class="px-3  " style={{width:'50%'}}>
                                  <div class="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                      <div class="w-full flex items-center">
                                          <div class="flex items-center justify-center overflow-hidden rounded-lg w-16 h-16 bg-gray-50 border border-gray-200">
                                              <img src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt=""/>
                                          </div>
                                          <div class="flex-grow pl-3">
                                              <h6 class="fjalla uppercase text-gray-800" style={{fontSize:'13px'}}>kesht  {plan} Plan {length == 'month' ? 'Monthly Subcription Fee' : length == '3months' ? '3 Months Subcription Fee' : 'Annual Subcription Fee' }</h6>
                                          </div>
                                          <div>
                                          <span class="anton" style={{fontSize:"20px"}}>
                                          {plan == 'basic' && length == 'month' && accountType == 'Institute' && '20,000 '}
                                              {plan == 'basic' && length == '3months' && accountType == 'Institute' && '30,000 '}
                                              {plan == 'basic' && length == 'annual' && accountType == 'Institute' && '60,000 '}
                                              {plan == 'premium' && length == 'month' && accountType == 'Institute' && '50,000 '}
                                              {plan == 'premium' && length == '3months' && accountType == 'Institute' && '75,000 '}
                                              {plan == 'premium' && length == 'annual' && accountType == 'Institute' && '150,000 '}
                                            
                                          
                                            
                                            ETB</span>
                                          </div>
                                      </div>
                                  </div>
                                  {
                                      cbebirr == true ?
                                      <div>
                                      <div class="flex items-center gap-3 mb-2">
                  <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                    <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center  rounded-md h-full w-full object-contain p-1"/>
                  </div>
                  <div class="flex flex-col">
                    <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                    </p>
                  </div>
                </div>
                               <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                  <div className="relative">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
      
      <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
          border-gray-300 rounded-md" />
         
      <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>

       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
      
      <div className="relative my-6">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
      
      <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
          border-gray-300 rounded-md borel" />
         
      <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>

   <div className='flex items-center'>
<input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

   <button
      className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
      onClick={() => {
        document.getElementById('hidden-input-img').click();
      }}
    >
      Upload Screenshot
    </button> 


   <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

     
   </div>
   {
    fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

   }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
        
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
                              </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              
              {
                                      telebirr == true ?
                                      <div>
                                      <div class="flex items-center gap-3 mb-2">
                  <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                  <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                  
                  </div>
                  <div class="flex flex-col">
                    <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                    </p>
                  </div>
                </div>
                               <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                  <div className="relative">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
      
      <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
          border-gray-300 rounded-md" />
         
      <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>

       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
      
      <div className="relative my-6">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
      
      <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
          border-gray-300 rounded-md borel" />
         
      <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>

   <div className='flex items-center'>
<input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

   <button
      className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
      onClick={() => {
        document.getElementById('hidden-input-img').click();
      }}
    >
      Upload Screenshot
    </button> 


   <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

     
   </div>
   {
    fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

   }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
        
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
                              </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              {
                                      cbe == true ?
                                      <div>
                                      <div class="flex items-center gap-3 mb-2">
                                      <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                            </p>
                          </div>
                </div>
                               <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                  <div className="relative">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
      
      <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
          border-gray-300 rounded-md" />
         
      <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>

       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
      
      <div className="relative my-6">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
      
      <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
          border-gray-300 rounded-md borel" />
         
      <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>

   <div className='flex items-center'>
<input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

   <button
      className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
      onClick={() => {
        document.getElementById('hidden-input-img').click();
      }}
    >
      Upload Screenshot
    </button> 


   <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

     
   </div>
   {
    fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

   }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
        
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
                              </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
              
              {
                                      boa == true ?
                                      <div>
                                      <div class="flex items-center gap-3 mb-2">
                                      <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                          </div>
                          <div class="flex flex-col">
                            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                            </p>
                          </div>
                </div>
                               <p className='fjalla ' style={{fontSize:'11px'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> via CBE Birr </p>
                                  <div class="relative m-auto my-10" style={{width:"90%"}}>
                                  <div className="relative">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize fjalla" style={{fontSize:'13px'}}>Account Holder's Name</p>
      
      <input onChange={e=>fullNameSet(e)}   style={{height:'80px',fontSize:'11px'}} required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white borel
          border-gray-300 rounded-md" />
         
      <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>

       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
      
      <div className="relative my-6">
      
      <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize" style={{fontSize:'13px'}}>Account Holder's Account</p>
      
      <input onChange={e=>transferredFromSet(e)} style={{height:'80px',fontSize:'11px'}}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
          focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-600 block bg-white
          border-gray-300 rounded-md borel" />
         
      <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>

   <div className='flex items-center'>
<input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

   <button
      className="px-5 py-2 mt-2 text-white rounded-sm borel   bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
      onClick={() => {
        document.getElementById('hidden-input-img').click();
      }}
    >
      Upload Screenshot
    </button> 


   <p className='fjalla ml-2' style={{fontSize:'11px'}}> {screenshot.name}</p>

     
   </div>
   {
    fileMesssage && <p style={{fontSize:'11px'}} className='Mooli text-red-800 border-red-800 border bg-red-50 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>

   }

<div className={`${screenshotError == "error" ? "block":"hidden"}`}>
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
       <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="sr-only">Info</span>
      <div className='Mooli' style={{fontSize:'11px'}}>
        
       This Field Can't be empty
      </div>
      </div>
      </div>
      </div>
                              </div>
                                      :
                                      <div>
              
                                      </div>
                                  }
                      
                         
                                  <div class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                      <div class="w-full flex items-center">
                                          <div class="flex-grow">
                                              <span class="text-gray-800 fjalla">Total</span>
                                          </div>
                                          <div class="pl-3">
                                              <span class="anton" style={{fontSize:"20px"}}>
                                          {plan == 'basic' && length == 'month' && accountType == 'Institute' && '20,000 '}
                                              {plan == 'basic' && length == '3months' && accountType == 'Institute' && '30,000 '}
                                              {plan == 'basic' && length == 'annual' && accountType == 'Institute' && '60,000 '}
                                              {plan == 'premium' && length == 'month' && accountType == 'Institute' && '50,000 '}
                                              {plan == 'premium' && length == '3months' && accountType == 'Institute' && '75,000 '}
                                              {plan == 'premium' && length == 'annual' && accountType == 'Institute' && '150,000 '}
                                            
                                          
                                            
                                            ETB</span>



                                          </div>
                                      </div>
                                  </div>
                                    {
                                      plan == 'free'?
                                      <Link to='/dashboard'>
                                         <button onClick={()=>handlePaidDesktop(checkoutId)} type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Start For Free
                                  </button></Link>
                                      :
                                      <button onClick={()=>handlePaidDesktop(checkoutId)} type='submit' style={{fontSize:'13px'}}   className="bg-blue-800 fjalla w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5  text-center text-white 
                                      rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">I have transferred the funds
                                  </button>
                                    }
                              </div>
                              
                              <div class="px-3" style={{width:'45%'}}>
              
                          
              
                                                                    {
                                                                      
            plan != 'free' &&

            <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-center text-gray-800 font-light mb-6">

            <div>
            <span style={{fontSize:"30px"}} class="text-center anton">Choose Payment Method</span>

            <div className='flex justify-evenly'>
            <div onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
           
                       <div style={{width:'70px',height:'70px'}}class=" rounded-md  border-blue-gray-50 p-1">
                         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                       </div>
                       <div class="flex flex-col">
                         <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                         </p>
                       </div>
                     </div>
                               <div onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
           
                       <div style={{width:'70px',height:'70px'}}class=" rounded-md  border-blue-gray-50 p-1">
                         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                       </div>
                       <div class="flex flex-col">
                         <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                         </p>
                       </div>
                     </div>
                               <div onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
           
                       
                       <div style={{width:'70px',height:'70px'}}class=" rounded-md  border-blue-gray-50 p-1">
                         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                       </div>
                       <div class="flex flex-col">
                         <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE
                         </p>
                       </div>
                     </div>
           
                     <div onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
                       <div style={{width:'70px',height:'70px'}}class=" rounded-md  border-blue-gray-50 p-1">
                         <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                       </div>
                       <div class="flex flex-col">
                         <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">BOA
                         </p>
                       </div>
                     </div>
           
            </div>
            </div>

</div>
         
           }
                               
                                  <div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                
              </div>
              <div id='mobile' class="  ">
                     <DashboardNav/>
                     <div class="px-5">
                       
                         <div class=" flex w-full justify-between">
                        
                         <h1 class=" font-bold text-gray-900 anton " style={{fontSize:'8vw',marginBottom:'3vw',paddingLeft:'3vw',paddingTop:'5vw'}}>Checkout for kesht Basic</h1>
                         </div>
                         <div class="mb-5 text-gray-400 flex ">
                         </div>
                     </div>
                     <p className='fjalla text-green-800 w-full bg-green-50  text-center'style={{fontSize:'3vw',padding:'2vw 5vw'}}>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
             
            
                     <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                         <div class="w-full">
                             <div class="">
                                 <div class=" ">
                                     <div class="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                         <div class="w-full flex items-center" style={{marginBottom:'5vw'}}>
                                       
                                             <div class="flex flex-col " style={{width:'80%'}}>
                                                 <h6 class="Mooli uppercase text-gray-600" style={{fontSize:'3.3vw',fontWeight:'800',paddingLeft:'4vw'}}>kesht  {plan} Plan {length == 'month' ? 'Monthly Subcription Fee' : length == '3months' ? '3 Months Subcription Fee' : 'Annual Subcription Fee' }</h6>
                                             
                                             
                                             </div>
                                             
                                             <div>
                                              
                                             <span class="anton" style={{fontSize:"4vw"}}>
                                            {amount}
                                             
                                               
                                               ETB</span>
                                             </div>
                                         </div>
                                                            
                 
                                         {
                                                                         
                                                                         plan != 'free' &&
                                                             
                                                                         <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-center text-gray-800 font-light mb-6">
                                                             
                                                                         <div>
                                                                         <span style={{fontSize:"6vw",marginBottom:'5vw'}} class="text-center anton">Choose Payment Method</span>
                                                             
                                                                         <div className='flex justify-evenly' style={{marginTop:'5vw'}}>
                                                                         <div onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    <div class=" rounded-md  border-blue-gray-50 "style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p style={{fontSize:'3vw'}}class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                                            <div onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    <div class=" rounded-md  border-blue-gray-50 "style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p style={{fontSize:'3vw'}}class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                                            <div onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                        
                                                                                    
                                                                                    <div class=" rounded-md  border-blue-gray-50 " style={{height:'17vw'}}> 
                                                                                      <img style={{width:'80%'}}src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize" style={{fontSize:'3vw'}}>CBE
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                        
                                                                                  <div onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border   rounded ">
                                                                                    <div class=" rounded-md  border-blue-gray-50 " style={{height:'17vw'}}>
                                                                                      <img style={{width:'80%'}} src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                                                                                    </div>
                                                                                    <div class="flex flex-col">
                                                                                      <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize" style={{fontSize:'3vw'}}>BOA
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                        
                                                                         </div>
                                                                         </div>
                                                             
                                                             </div>
                                                                      
                                                                        }
                                     </div>
                                     {
                                         cbebirr == true ?
                                         <div>
                                                 <div class="flex items-center gap-3 mb-2">
                             <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                               </p>
                             </div>
                           </div>
                                          <p className='fjalla ' style={{fontSize:'3vw'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                  
                 <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

           <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black   text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
              <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
           
           <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"6vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

           <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
                 focus:border-black w-full  text-gray-700 block bg-white
                 border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
              <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
                 
               
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
          
               <button    onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
    Upload Screenshot

  </button>
   
       
              <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 
                 {
                                         telebirr == true ?
                           
                                               <div>
                                               <div class="flex items-center gap-3 mb-2">
                           <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                             <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                           </div>
                           <div class="flex flex-col">
                             <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                            
                             </p>
                           </div>
                         </div>
                                        <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                
               <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
         
         <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>
      
         <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
               focus:border-black   text-gray-700 block bg-white
               border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
            <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      Account Holder Can't be Empty
      </div>
      </div>
      </div> 
      </div> 
      <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
         
         <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>
      
         <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
               focus:border-black w-full  text-gray-700 block bg-white
               border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
            <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      Account Holder's Account Can't be Empty
      </div>
      </div>
      </div> 
      </div> 
               
             
      
            <div className='flex items-center'>
       <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
      
        
             <button    onClick={() => {
                 document.getElementById('hidden-input-img').click();
               }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
      Upload Screenshot
      
      </button>
      
      
            <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>
      
              
            </div>
            {
             fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>
      
            }
      
      <div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
      <div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
      <svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className='Mooli' style={{fontSize:'3vw'}}>
      This Field Can't be Empty
      </div>
      </div>
      </div>
                                       </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         cbe == true ?
                                         <div>
                                         <div class="flex items-center gap-3 mb-2">
                     <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                       <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                     </div>
                     <div class="flex flex-col">
                       <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                      
                       </p>
                     </div>
                   </div>
                                  <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
          
         <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

   <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black   text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
      <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

   <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black w-full  text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
      <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
         
       

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

  
       <button    onClick={() => {
           document.getElementById('hidden-input-img').click();
         }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
Upload Screenshot

</button>


      <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>

      }

<div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                 </div>
                                 
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         boa == true ?

                                         <div>
                                         <div class="flex items-center gap-3 mb-2">
                     <div style={{width:'11vw',height:'11vw'}} class=" rounded-md border border-blue-gray-50 p-1">
                       <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                     </div>
                     <div class="flex flex-col">
                       <p style={{fontSize:'4vw'}} class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                      
                       </p>
                     </div>
                   </div>
                                  <p className='fjalla ' style={{fontSize:'3.5vw'}}>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
          
         <div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"10vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Name</p>

   <input type='text'  placeholder='Abebe Solomon' className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black   text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw",width:'100%'}}   onChange={e=>fullNameSet(e)}/>
      <div className={`${fullNameError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder Can't be Empty
</div>
</div>
</div> 
</div> 
<div className="relative m-auto " style={{width:"90%",marginBottom:"7vw",marginTop:"7vw",lineHeight:"4vw"}}>
   
   <p class="bg-white  font-medium text-gray-600 absolute "style={{backgroundColor:"white",fontSize:"3vw",marginLeft:"3vw",marginTop:"-2.5vw",paddingLeft:"2vw",paddingRight:"2vw"}}>Account Holder's Account</p>

   <input type='text'  placeholder="0912345678" className="w-full m-auto form-control text-black border  focus:outline-none
         focus:border-black w-full  text-gray-700 block bg-white
         border-gray-300 rounded-md" style={{width:'100%',paddingTop:"6vw",paddingBottom:"6vw",paddingLeft:"4vw",fontSize:"3vw"}}   onChange={e=>transferredFromSet(e)}/>
      <div className={`${transferredFromError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
Account Holder's Account Can't be Empty
</div>
</div>
</div> 
</div> 
         
       

      <div className='flex items-center'>
 <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />

  
       <button    onClick={() => {
           document.getElementById('hidden-input-img').click();
         }} type="submit" style={{ fontSize: "4vw", width: "50vw", height: "13vw",background:'black' }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
Upload Screenshot

</button>


      <p className='fjalla ml-2' style={{fontSize:'3.5vw'}}> {screenshot.name}</p>

        
      </div>
      {
       fileMesssage && <p className='fjalla  text-red-800 border border-red-300 rounded-lg bg-red-50 rounded' style={{padding:'3vw 2vw', fontSize:'3vw',marginTop:'3vw'}}>{fileMesssage}</p>

      }

<div className={`${screenshotError.length != 0 ? "block":"hidden"}`}>
<div style={{padding:'3vw 2vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg style={{width:'5vw',height:'5vw',marginRight:'2vw'}} className="flex-shrink-0 inline " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<div className='Mooli' style={{fontSize:'3vw'}}>
This Field Can't be Empty
</div>
</div>
</div>
                                 </div>
                                      
                                         :
                                         <div>
                 
                                         </div>
                                     }
                         
                            
                                     <div class=" " style={{margin:'10vw 0'}}>
                                         <div class="w-full flex items-center">
                                             <div class="flex-grow">
                                                 <span class="text-gray-800 fjalla" style={{fontSize:'4vw'}}>Total</span>
                                             </div>
                                             <div class="pl-3">
                                                 <span class="font-semibold text-gray-400 text-sm"></span> <span class="anton" style={{fontSize:"4.5vw"}}>{plan == 'free' && '0 '}
                                 {amount}
   
   
                                               ETB</span>
                                             </div>
                                         </div>
                                     </div>
                                       {
                                         plan == 'free'?
                                         <Link to='/dashboard'>
                                            <button type="submit" style={{ backgroundColor: "#2141ce", fontSize: "4vw", width: "90%", height: "15vw" }} class="w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
                                        Start For Free
                                      </button></Link>
                                         :
                                   
                                      <div class="flex justify-center">
                                      <button onClick={()=>handlePaidDesktop(checkoutId)}type="submit" style={{ backgroundColor: "#2141ce", fontSize: "4vw", width: "90%", height: "15vw" }} class="fjalla w-full flex justify-center items-center  text-xl font-medium text-center text-white rounded-lg transition duration-200 hover:bg-indigo-600 ease cursor-pointer">
                                        I have transferred the funds
                                      </button>
                                    </div>
                                       }
                                 </div>
                                 
                                 <div class="px-3 md:w-5/12">
                 
          
                                  
                                     <div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                   
                 </div>
                 <div id='tablet' class=" ">
                     
                     <div class="px-5">
                       
                         <div class="mb-2 flex w-full justify-between">
                        
                         <h1 class=" font-bold text-gray-900 anton text-center w-full mt-10">Checkout for kesht Basic</h1>
                         </div>
                         <div class=" text-gray-400 flex ">
                         </div>
                     </div>
                     <p className='fjalla text-green-800 w-full bg-green-50 py-2 text-center px-5'>A screenshot of the transaction must be sent once the funds have been transferred to the specified bank account</p>
                     
            
                     <div class="w-full bg-white border-t  border-gray-200 px-5 py-10 text-gray-800">
                         <div class="w-full">
                             <div class="">
                                 <div class="">
                                     <div class="w-full mx-auto text-gray-800 font-light mb-6  border-gray-200 pb-6">
                                         <div class="w-full flex items-center mb-10">
                                             <div class="flex items-center justify-center overflow-hidden rounded-lg w-16 h-16 bg-gray-50 border border-gray-200">
                                                 <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/kesht%20(5).png?alt=media&token=7d713581-1de4-4745-9686-2a8f504d36d0" alt=""/>
                                             </div>
                                             <div class="flex-grow pl-3 w-full ">
                                                 <h6 class="font-semibold uppercase text-gray-600" style={{width:'70%'}}>kesht  {plan} Plan {length == 'month' ? 'Monthly Subcription Fee' : length == '3months' ? '3 Months Subcription Fee' : 'Annual Subcription Fee' }</h6>
                                             </div>
                                             <div style={{width:'50%'}} className='flex '>
                                             <span class="anton w-full text-right " style={{fontSize:"20px"}}>
                                            {amount}
                                             
                                               
                                               ETB</span>
                                             </div>
                                         </div>
                                         <span style={{fontSize:"30px"}} class="text-center anton">Choose Payment Method</span>
   
   <div className='flex justify-evenly'>
   <div onClick={handleCbeBirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
  
              <div class="h-20 w-20 rounded-md  border-blue-gray-50 p-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
              </div>
              <div class="flex flex-col">
                <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE BIRR
                </p>
              </div>
            </div>
                      <div onClick={handleTelebirrDesktop}  class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
  
              <div class="h-20 w-20 rounded-md  border-blue-gray-50 p-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
              </div>
              <div class="flex flex-col">
                <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">TELEBIRR
                </p>
              </div>
            </div>
                      <div onClick={handleCbeDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
  
              
              <div class="h-20 w-20 rounded-md  border-blue-gray-50 p-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
              </div>
              <div class="flex flex-col">
                <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">CBE
                </p>
              </div>
            </div>
  
            <div onClick={handleBoaDesktop} class="paymentMethod cursor-pointer flex flex-col items-center gap-3 hover:border  px-3 py-2 rounded ">
              <div class="h-20 w-20 rounded-md  border-blue-gray-50 p-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
              </div>
              <div class="flex flex-col">
                <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">BOA
                </p>
              </div>
            </div>
  
   </div>
                                     </div>
                                     {
                                         cbebirr == true ?
                                         <div>
                                                 <div class="flex items-center gap-3 mb-2">
                             <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Cbe birr
                               </p>
                             </div>
                           </div>
                                          <p className='fjalla '>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                                             <div class="relative m-auto my-10" style={{width:"90%"}}>
                                             <div className="relative">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Name</p>
                 
                 <input onChange={e=>fullNameSet(e)}   required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                 <div className="relative my-6">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Account</p>
                 
                 <input onChange={e=>transferredFromSet(e)}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
              <button
                 className="px-5 py-2 mt-2 text-white rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                 onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }}
               >
                 Upload Screenshot
               </button> 
   
       
              <p className='fjalla ml-2'> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla text-white bg-red-800 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 
                 {
                                         telebirr == true ?
                                         <div>
                                             <div >
                                                    <div class="flex items-center gap-3 mb-2">
                             <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">Telebirr
                               </p>
                             </div>
                           </div>
                                          <p className='fjalla '>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                                     
                                             <div class="relative m-auto my-10" style={{width:"90%"}}>
                 
                 
                                           
                 
                 
                                             <div className="relative">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Name</p>
                 
                 <input onChange={e=>fullNameSet(e)}   required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                 <div className="relative my-6">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Account</p>
                 
                 <input onChange={e=>transferredFromSet(e)}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
              <button
                 className="px-5 py-2 mt-2 text-white rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                 onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }}
               >
                 Upload Screenshot
               </button> 
   
       
              <p className='fjalla ml-2'> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla text-white bg-red-800 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                                             </div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         cbe == true ?
                                         <div>
                                                                   <div class="flex items-center gap-3 mb-2">
                             <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">commercial bank of ethiopia
                               </p>
                             </div>
                             
                           </div>
                           <p className='fjalla '>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                 
                                             <div class="relative m-auto my-10" style={{width:"90%"}}>
                 
                 
                                            
                                             <div className="relative">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Name</p>
                 
                 <input onChange={e=>fullNameSet(e)}   required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                 <div className="relative my-6">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Account</p>
                 
                 <input onChange={e=>transferredFromSet(e)}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
              <button
                 className="px-5 py-2 mt-2 text-white rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                 onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }}
               >
                 Upload Screenshot
               </button> 
   
       
              <p className='fjalla ml-2'> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla text-white bg-red-800 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 
                 </div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                 
                 {
                                         boa == true ?
                                         <div>
                                                                                     <div class="flex items-center gap-3 mb-2">
                             <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                               <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
                             </div>
                             <div class="flex flex-col">
                               <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">bank of abyssinia
                               </p>
                             </div>
                             
                           </div>
                           <p className='fjalla '>Transfer {amount} ETB to <span className='text-blue-800 underline'>0923580987 </span> </p>
                 
                                             <div class="relative m-auto my-10" style={{width:"90%"}}>
                                           
                                              
                 
                                            
                                             <div className="relative">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Name</p>
                 
                 <input onChange={e=>fullNameSet(e)}   required  autoComplete="off" placeholder="Abebe Solomon" type="text" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${fullNameError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
                 
                 <div className="relative my-6">
                 
                 <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute capitalize">Account Holder's Account</p>
                 
                 <input onChange={e=>transferredFromSet(e)}   required  autoComplete="off" placeholder="0912345678" type="number" className="border  focus:outline-none
                     focus:border-black w-full pt-5 pr-4 pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                     border-gray-300 rounded-md" />
                    
                 <div className={`${transferredFromError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div>
                 </div>
       
              <div className='flex items-center'>
         <input accept="image/jpeg, image/png, image/gif" type="file" id="hidden-input-img"  className="hidden" onChange={event=>handleImgSelect(event)} />
   
              <button
                 className="px-5 py-2 mt-2 text-white rounded-sm px-3 py-1 bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
                 onClick={() => {
                   document.getElementById('hidden-input-img').click();
                 }}
               >
                 Upload Screenshot
               </button> 
   
   
       
              <p className='fjalla ml-2'> {screenshot.name}</p>
   
                
              </div>
              {
               fileMesssage && <p className='fjalla text-white bg-red-800 px-2 py-1.5 mt-2 rounded'>{fileMesssage}</p>
   
              }
   
   <div className={`${screenshotError == "error" ? "block":"hidden"}`}>
                 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
                 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                 </svg>
                 <span className="sr-only">Info</span>
                 <div className='Mooli'>
                  This Field Can't be empty
                 </div>
                 </div>
                 </div></div>
                                         </div>
                                         :
                                         <div>
                 
                                         </div>
                                     }
                         
                            
                                     <div class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                         <div class="w-full flex items-center">
                                             <div class="flex-grow">
                                                 <span class="text-gray-800 fjalla">Total</span>
                                             </div>
                                             <div class="pl-3">
                                                 <span class="font-semibold text-gray-400 text-sm"></span> <span class="anton" style={{fontSize:"25px"}}>{plan == 'free' && '0 '}
                                 {amount}
   
   
                                               ETB</span>
                                             </div>
                                         </div>
                                     </div>
                                       {
                                         plan == 'free'?
                                         <Link to='/dashboard'>
                                            <button onClick={()=>handlePaidDesktop(checkoutId)} type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">Start For Free
                                     </button></Link>
                                         :
                                         <button onClick={()=>handlePaidDesktop(checkoutId)} type='submit'   className="bg-blue-800 w-full flex justify-center items-center pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white 
                                         rounded-lg transition duration-200 hover:bg-blue-700 ease cursor-pointer capitalize">I have transferred the funds
                                     </button>
                                       }
                                 </div>
                                 
                                 <div class="">
                 
                                 
                 
                                                                       {
                                                                         
               plan != 'free' &&
   
               <div class="w-full mx-auto rounded-lg bg-white  border-gray-200 p-3 text-center text-gray-800 font-light mb-6">
   
               <div>
       
               </div>
   
   </div>
            
              }
                                  
                                     <div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                   
                 </div>
              </div>

                  
               }
            </div>
            
            }
          </div>

        
      
          
          
   



</body>
      
         

</div>
            </div>

            :
       users.map(i=>{
        const {photo,fullName} = i
        return(
          <div>
          <section class="bg-white  " id='desktop'>
          <nav className='pt-6 flex justify-between items-center  px-2 w-full'>
          <div className='flex justify-between items-center w-full relative mb-5' >
   <div className='flex justify-evenly'>
  
  <Link to="/dashboard" className=' ' style={{width:"47%",objectFit:"cover"}}> 
                       <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                         </Link>
  
               <div>
  
  </div>
  
  
    
   </div>
   
   
         <ul className='flex items-center'>
    {
     plan == 'Free' &&
     <Link to='/pricing'>
     <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Upgrade Plan</p>
     </Link>
   
  
   
    }
        {/* <p className='text-blue-800 mr-4 hover:underline Mooli cursor-pointer' style={{fontSize:'12px'}}>Need Help</p> */}
           <div  className="dropdown   mr-5 ">
             
             <div className='notibell relative mt-2'>
            {/* <p className='notificationnumber rounded'>{notification.length}</p> */}
   <div>
             <div onClick={popNotification} className="notibell  text-gray-500  p-0 m-0 hover:text-gray-900  transition-all ease-in-out duration-300" style={{border:"none"}}>
               <button    style={{color:"black"}}className=" inline-block relative">
       <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
       </svg>
       <span className="animate-ping absolute top-1 right-0.5 block h-1 w-1 rounded-full ring-2 ring-blue-400 bg-blue-600"></span>
   </button>
             </div>
             </div>
             </div>
             {
              notificationPop == true &&
              <div id='notification' className="menu notpop border  rounded bg-white   shadow-md  z-20 w-84  pt-2 animated faster">
              {/* top */}
              <div   className=" px-4 py-2 flex flex-row justify-between items-center capitalize font-semibold text-sm">
                <h1 className='py-3 Mooli' style={{fontSize:'15px'}}>Notifications</h1>
                <div style={{fontSize:'15px'}} className="bg-teal-100 border border-teal-200 text-teal-500 text-xs rounded px-1">
                  {/* <strong>{notification.length}</strong> */}
                  {notificationFilter && notificationFilter.length}
                </div>
              </div>
              <hr />
              {/* end top */}
              {/* body */}
              {/* item */}
            
              {/* end item */}
              {/* item */}
    
              {/* end body */}
              {/* bottom */}
           
  
              {
                notificationFilter && notificationFilter.length != 0 ?
                <div>
                  {
                notificationFilter.map(i=>{
                  const {notificationTitle,declinedReason,time,id} = i
                  return(
     <Link to='/notification'>
                    <div className='relative border-b  px-2 hover:bg-gray-50 cursor-pointer'>
  
  <div className='relative  flex items-center justify-between  '>
  <svg onClick={()=>removeNotification(id)} style={{position:'absolute',top:'10px',right:'10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
  </svg>
  <p className='text-gray-600  py-5 px-3 borel' style={{width:'90%',fontSize:'12px'}}>
  {notificationTitle} <br />
                  </p>
  
                  <p className='fjalla' style={{fontSize:'12px'}}>
                    {convertTimestampToDate(time)}
  
                  </p>
                
  </div>
  
  </div></Link>
  
                  )
                }) }
                <div className=" px-4 py-2 mt-2 fjalla">
                <Link
                  to="/notification"
                  className="border fjalla border-gray-300 block text-center text-xs uppercase rounded p-1 hover:text-teal-500 transition-all ease-in-out duration-500"
                >
                  view all
                </Link>
              
              </div>
                </div>
                :
                <div> 
                <h1 className=' text-center pt-4 pb-2 fjalla' style={{fontSize:"20px"}}>
            No new notifications
  
                </h1>
                <p className='text-gray-400 text-center pb-4 px-3' style={{fontSize:"12px"}}>
                You'll be notified when new stuff happens
  
                </p>
            </div> 
              }
  
  
  
       
           
              {/* end bottom */}
            </div>
             }
         
           </div>
           <div className="flex  items-center">
           {/* user */}
           <div className="dropdown relative "onClick={popProfile} >
           
             <button  className="menu-btn focus:outline-none e flex flex-wrap items-center" >
             
             <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
         
   
   
   
              
   {
                   photo.length == 0 ?  
                 <div
                   className="flex  items-center justify-center  bg-blue-300  w-12 h-12 m-auto rounded-full object-cover "
                 style={{width:"40px",height:"40px",fontSize:'12px'}}
                 >
                   {fullName[0]}
   
                 </div>
                 :
                 <img className="w-full h-full object-cover" alt='' src={photo} />
   
   
                 }
           </div>
           
               <div className="ml-2 capitalize flex ">
          
         
               </div>
             </button>
             {
              pop == true && 
              <div id='profileData'  className=" text-gray-500    rounded border bg-white absolute   animated faster">
              {/* item */}
              <Link to="/profile"
              style={{fontSize:'12px'}}
                className="w-full   Mooli px-4 py-3 flex capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
              >
       
                 Profile
              </Link>
              {/* end item */}
              {/* item */}
            
      
              {/* item */}
              <div
              onClick={handleLogOut}
              style={{fontSize:'12px'}}
  
                className="cursor-pointer px-4 Mooli py-3 fle block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out"
              >
                Log out
              </div>
              {/* end item */}
            </div>
             }
           
           </div>
         
         </div>
   
   
         </ul>
         </div>
   
         </nav>
        
              <div>
              <ul className='flex justify-start border-b'>
                <div className=' flex justify-evenly   '>
                      <Link to='/dashboard'>
                   <li className='py-2.5 cursor-pointer montserrat   ' style={{marginLeft:'3vw',fontSize:'12px' }}>Home</li>
        
                      </Link>
        <Link to='/mystudents' style={{marginLeft:'1.5vw',fontSize:'12px' }}>
        <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800 ' >My Students</li>
        
                      </Link>
        <Link to="/mycourse" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
        <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Courses</li>
        
                      </Link>
                      <Link to="/transactions" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
        <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Transactions</li>
        
                      </Link>
        
                      <Link to="/certificate" style={{marginLeft:'1.5vw',fontSize:'12px' }}>
        <li className='py-2.5 cursor-pointer montserrat hover:text-blue-800' >Certificates</li>
        
                      </Link>
        
                   </div>
                  
                 
        
        
        
        
                </ul>
              </div>
          <div class=" flex items-center  px-6 py-12 mx-auto">
              <div class="flex flex-col items-center max-w-sm mx-auto text-center">
                  <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                      </svg>
                  </p>
                  <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
                  Page not found</h1>
                  <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>
      
                  <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
         <Link to='/pricing'>
         <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                          </svg>
      
      
                          <span>Go back</span>
                      </button></Link>
            <Link to='/contact'>
                      <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                          Contact Us
                      </button>
            </Link>
      
                  </div>
              </div>
          </div>
      </section>
      <section class="bg-white  " id='tablet'>
          <div class=" flex items-center  px-6 py-12 mx-auto">
              <div class="flex flex-col items-center max-w-sm mx-auto text-center">
                  <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                      </svg>
                  </p>
                  <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
                  Page not found</h1>
                  <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>
      
                  <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
         <Link to='/pricing'>
         <button class="flex items-center justify-center  px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                          </svg>
      
      
                          <span>Go back</span>
                      </button></Link>
            <Link to='/contact'>
                      <button class=" px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                          Contact Us
                      </button>
            </Link>
      
                  </div>
              </div>
          </div>
      </section>
      <section class="bg-white  " id='mobile'>
          <div class=" flex items-center  px-6 py-12 mx-auto">
              <div class="flex flex-col items-center max-w-sm mx-auto text-center">
                  <p class="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                      </svg>
                  </p>
                  <h1 style={{fontSize:'30px',fontWeight:'900'}} className='font-extrabold tracking-tight text-gray-800  text-9xl'>
                  Page not found</h1>
                  <p class="mt-4 text-gray-500 ">The page you are looking for doesn't exist.</p>
      
                  <div class="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
         <Link to='/pricing'>
         <button class="flex items-center justify-center  px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto ">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 rtl:rotate-180">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                          </svg>
      
      
                          <span>Go back</span>
                      </button></Link>
            <Link to='/contact'>
                      <button class=" px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-800 rounded-lg shrink-0 sm:w-auto hover:bg-blue-900 ">
                          Contact Us
                      </button>
            </Link>
      
                  </div>
              </div>
          </div>
      </section>
          </div>

        )
       })
          )
        })
       }

    </div>
  )
}
