import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { auth, firestore } from '../firebase';
import { collection, onSnapshot,query,orderBy, doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import aws from 'aws-sdk';
import { uploadFile } from 'react-s3';
import { v4 } from 'uuid';
import ReactPaginate from 'react-paginate';
import Nav from '../home/Nav';
import DashboardNav from './dashboardNav';

export default function Courses() {
    const [spinner,setspinner] = useState(false)
    const [fetched,setfetched] = useState(false)

    
    const [user,setuser] = useState([])
    const [uid,setuid] = useState([])
    const [courses,setcourses] = useState([])
    const [search,setSearch] = useState('')
    const [searched,setSearched] = useState('')
    const [pageNumber,setPageNumber] = useState(0)
    const [pop,setpop] = useState(false)







    const navigate = useNavigate()


    const popNotification = () =>{
      const notification = document.getElementById("notification");
      if(notification.style.display === ("block")){
          notification.style.display=("none")

      }
      else{
      notification.style.display=("block")


      }
  }

  const popProfile = () =>{
    const profile = document.getElementById("profileData");
    if(profile.style.display === ("block")){
        profile.style.display=("none")

    }
    else{
    profile.style.display=("block")


    }
}

function mobileNavPop(){
  setpop(true)




}

// Mobile Nav Pop minimize
function mobileNavPopMinimize(){
setpop(false)



}

    useEffect(() => {
        const coursesRef = collection(firestore,"Courses");

        onSnapshot(coursesRef,snapshot=>{
   
            let main =[]
        
            snapshot.forEach(doc=>{
                main.push({...doc.data(),id:snapshot.id})
              
            })
        
              
          setcourses(main)
          setfetched(true)
        
            
          
        
          })
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
          //  setcred(uid)
        setuid(uid);
           
    
       
        
    
      const teacherRef = doc(firestore,"TotalUsers",uid);
      const userRef = doc(firestore,"TotalUsers",uid);
    
      onSnapshot(userRef,snapshot=>{
       
        let main =[]
    
    
    
          main.push({...snapshot.data(),id:snapshot.id})
          
      setuser(main)
    
        
        
      
    
      })

     
      
   
    
    
    
    
    
           
      
    
    
    
    
            
    
               
     
      
                
      
      
      
      
      
      
                
      
                  
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
     
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);

    

function searchFilter(e){
  e.preventDefault()
  setSearched(search)

}

      function handleLogOut(){
        signOut(auth).then(
         navigate('/')
        )
       }

       function desktopSpinnerHandler(){
        setspinner(false)
       }
       const userPerPage = 8
       const pagesVisited = pageNumber * userPerPage
       const filteredCourses = courses.filter(i=>i.status=== 'published').filter((item) => {
        if (searched === '') {
          return true;
        } else if (item.courseTitle.toLowerCase().includes(searched.toLowerCase())) {
          return true;
        }
        return false;
      })


      const pageCount = Math.ceil(filteredCourses.length / userPerPage)
      function changePage({selected}){
          setPageNumber(selected)

        }

  return (
<div>
{
  fetched == false &&
  <div id='spinnerDesktop'>
  <div
class="inline-block h-12 w-12 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-blue-800 align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
role="status">
<span
class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
>Loading...</span>
</div>

  </div>
 }
    {
            user.length != 0 ?
            user.map(i=>{
            const{accountType,plan,fullName,photo} = i
    
            return(
                accountType == "Student"?
                <div>
          <div id='desktop' >
          <div id='stickynav' className='border-r border-b px-1'>
              <div className='w-full '>
                <Link to="/dashboard">
              <img onLoad={desktopSpinnerHandler}  className='mx-auto mt-5' style={{width:"120%",objectFit:"cover"}} src="https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0" alt="logo" /> 

              </Link>
              </div>
   

<div className="flex flex-col justify-center " >
<div className="  ">
      <nav aria-label="Main Nav" className="flex flex-col ">




    
  




        

        <ul className="space-y-1 border-t border-gray-100 pt-4">
          
<li>



  


  
<Link to="/achievements"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
        
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/certificate.png?alt=media&token=97b6c86e-2e5b-44e0-8293-9cb05f59d15c" alt="certificate" />

    
            </button>
        </Link>
<Link to="/courses"
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"


>
<button
             className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
             
           >
       
<img width="18" height="18" src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/online-learning.png?alt=media&token=f9d335fc-d143-448e-a5c5-15977ef3cc68" alt="certificate" />

   
           </button>
       </Link>



          
         
         </li>
        <Link to="/enrolledcourses"
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
   <li>
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"

            >
  
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
         
            </button>
          </li>

</Link>


          <li>



  
        



   


        
 


     
           
          
          </li>

          <li>

<Link to="/transactions"
              className="py-3 group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              >
            <button
              className="group relative flex justify-center rounded  py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              
            >
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>

          
            </button>
          </Link>
          </li>



<li>



  


  
        
 



           
          
          </li>
        </ul>
      </nav>
    </div>
 

  <div className="absolute sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2" style={{position:"absolute",bottom:0}}>
    <div >
      <button
       onClick={handleLogOut}
        className="group relative flex w-full justify-center rounded-lg  py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>

        <span 
         style={{zIndex:900}} className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
        >
          Logout
        </span>
      </button>
    </div>
  </div>
</div>
</div>
<div id='mainPage' style={{padding:'50px 0',marginBottom:'50px'}}>

    <div>
    <form class="flex items-center justify-center mx-auto" style={{width:"61%"}}>   
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-full">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"/>
            </svg>
        </div>
        <input onChange={e=>setSearch(e.target.value)} type="text" id="simple-search" class="oswald  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 px-2.5 py-5  focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Course Title" />
    </div>
    <button onClick={searchFilter} type="submit" style={{backgroundColor:"#000000ff"}} class="p-4 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
        <span class="sr-only">Search</span>
    </button>
</form>
    </div>
    <div className='courseGrid'>

    

{
filteredCourses.slice(pagesVisited,pagesVisited + userPerPage).map(i=>{
const {courseTitle,certificate,courseThumbnail,coursePricing,coursePrice,courseSkills,status,completionTime,courseLevel,totalLectures,courseId,intendedAgeGroup}  = i
return(

<Link to={`/course/${courseId}`} >

<div className="h-full border max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-md transform hover:scale-105 transition duration-500">
<h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
<div className="relative">
{
courseThumbnail == false ?
<img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>

}

<p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
</div>
<h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
<p style={{fontSize:"11px",marginLeft:'5px',marginBottom:'4px'}} class="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
    {
      certificate == true ?
    <p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
    :
    <p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>
    
    }
       
</div>
</Link>


)
})
}
</div>

{
  filteredCourses.length == 0 &&
  <div className='w-full anton text-center flex flex-col items-center capitalize mb-10 mt-10'>
  We couldn't find any matches for your search. Try refining it
  <img
    onLoad={desktopSpinnerHandler}
    style={{ width: '25%' }}
    src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
    alt='no content'
  />

</div>
}

{
          filteredCourses.length != 0  && 
          <div className='flex justify-center w-full' style={{marginTop:'80px',marginBottom:'80px'}}>
          <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          previousLinkClassName={"previous__link-pagination"}
          nextLinkClassName={"next__link-pagination"}
          containerClassName={"container-pagination flex"}
          disabledClassName={"disabled-pagination"}
          activeClassName={"active-pagination"}
          />
      
      </div>
         }
    
    
    </div>
    </div>
    <div id="mobile">
   
 <DashboardNav/>
      
    <form class="flex items-center justify-center mx-auto" style={{width:"70%",marginBottom:'10vw'}}>   
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-full">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"/>
            </svg>
        </div>
        <input onChange={e=>setSearch(e.target.value)} style={{fontSize:'3vw'}} type="text" id="simple-search" class="borel  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 px-2.5 py-5  focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Course Title" />
    </div>
    <button onClick={searchFilter} type="submit" style={{backgroundColor:"#000000ff"}} class="p-4 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
        <span class="sr-only">Search</span>
    </button>
</form>
    <div class="  text-gray-500 flex   mobileGridCourses " style={{overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>

    {
  filteredCourses.slice(pagesVisited,pagesVisited + userPerPage).map(i=>{
    const {courseTitle,certificate,courseThumbnail,intendedAgeGroup,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
    
    return(
      <Link to={`/course/${courseId}`}>
<div class=" border  bg-white rounded-2xl shadow-md relative" style={{backgroundColor:"white",width:'100%',height:'100%',paddingLeft:'2vw',paddingBottom:'10vw',paddingRight:'2vw',paddingTop:'8vw'}}>
        
        <h3 className="mb-3 text-blue-800 anton text-center" style={{fontSize:"5vw"}}>Beginner Friendly</h3>
        <div className="relative">
          {
            courseThumbnail == false ?
    <img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description" style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

          }

          <p className="absolute top-0 bg-blue-500 text-white   rounded-br-lg rounded-tl-lg fjalla" style={{padding:'1.5vw 2vw 1vw 1.5vw ',fontSize:'4vw'}}>{coursePrice} ETB</p>
        </div>
        <h1 className=" text-gray-800  fjalla capitalize" style={{fontSize:"5vw",marginLeft:'3vw',marginBottom:'2vw'}}>{courseTitle}</h1>
        <div className="flex flex-col justify-center">
    
<p style={{fontSize:"3vw",marginLeft:'3vw',marginBottom:'2vw'}} class="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
{
  certificate == true ?
<p style={{fontSize:"3vw",marginLeft:'3vw'}} class="uppercase absolute bottom-2 left-2 bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
:
<p style={{fontSize:"3vw",marginLeft:'3vw'}} class="uppercase absolute bottom-2 left-2 bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>

}
    
         
  
        </div>
      </div>
      </Link>

  
    )
  })
}
    </div>
    {
  filteredCourses.length == 0 &&
  
  <div className='w-full fjalla text-center flex flex-col items-center capitalize ' style={{fontSize:'5vw',padding:'10vw 5vw',lineHeight:'5.5vw'}}>

  {
    search.length !=0 ?
    "We couldn't find any matches for your search. Try refining it" :
    
    "There are no courses available at this time"
  }

<img
    onLoad={desktopSpinnerHandler}
    style={{ width: '50%' }}
    src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
    alt='no content'
  />
</div>
}

{
          filteredCourses.length != 0  && 
          <div className='flex justify-center w-full' style={{marginTop:'80px',marginBottom:'80px'}}>
          <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          previousLinkClassName={"previous__link-pagination"}
          nextLinkClassName={"next__link-pagination"}
          containerClassName={"container-pagination flex"}
          disabledClassName={"disabled-pagination"}
          activeClassName={"active-pagination"}
          />
      
      </div>
         }
    </div>

                </div>
                :
                <div>
      <div id="mobile">
 <DashboardNav/>
    <form class="flex items-center justify-center mx-auto" style={{width:"70%",marginBottom:'10vw'}}>   
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-full">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"/>
            </svg>
        </div>
        <input onChange={e=>setSearch(e.target.value)} type="text" id="simple-search" class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 px-2.5 py-5  focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Course Title" />
    </div>
    <button onClick={searchFilter} type="submit" style={{backgroundColor:"#000000ff"}} class="p-4 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
        <span class="sr-only">Search</span>
    </button>
</form>
    <div class="  text-gray-500 flex   mobileGridCourses " style={{overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>

    {
  filteredCourses.slice(pagesVisited,pagesVisited + userPerPage).map(i=>{
    const {courseTitle,courseThumbnail,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
    
    return(
      <Link to={`/course/${courseId}`}>
<div class=" border  bg-white rounded-2xl shadow-xl " style={{backgroundColor:"white",width:'100%',paddingLeft:'2vw',paddingBottom:'4vw',paddingRight:'2vw',paddingTop:'8vw'}}>
        
        <h3 className="mb-3 text-blue-800 anton text-center" style={{fontSize:"5vw"}}>Beginner Friendly</h3>
        <div className="relative">
          {
            courseThumbnail == false ?
    <img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description" style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

          }

          <p className="absolute top-0 bg-blue-500 text-white font-semibold  rounded-br-lg rounded-tl-lg fjalla" style={{padding:'1.5vw 2vw 1vw 1.5vw '}}>{coursePrice} ETB</p>
        </div>
        <h1 className=" text-gray-800  fjalla capitalize" style={{fontSize:"5vw",marginLeft:'3vw'}}>{courseTitle}</h1>
        <div className="">
    
    
          <div className="flex  items-center w-full">
            
         <div className='skillGrid   w-full overflow-hidden' >
          {
            courseSkills.map(i=>{
              return(
                <span style={{fontSize:"2vw",paddingLeft:'2vw',paddingRight:'2vw',paddingTop:'2vw',paddingBottom:'2vw'}} class="capitalize bg-green-200 text-green-600  rounded fjalla">{i}</span>

              )
            })
          }
  
         </div>
          </div>
 
  
        </div>
      </div>
      </Link>

  
    )
  })
}
    </div>
    {
  filteredCourses.length == 0 &&
  <div className='w-full anton text-center flex flex-col items-center capitalize ' style={{fontSize:'7vw',padding:'10vw 5vw'}}>
  We couldn't find any matches for your search. Try refining it
  <img
    onLoad={desktopSpinnerHandler}
    style={{ width: '50%' }}
    src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
    alt='no content'
  />

</div>
}

{
          filteredCourses.length != 0  && 
          <div className='flex justify-center w-full' style={{marginTop:'80px',marginBottom:'80px'}}>
          <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          previousLinkClassName={"previous__link-pagination"}
          nextLinkClassName={"next__link-pagination"}
          containerClassName={"container-pagination flex"}
          disabledClassName={"disabled-pagination"}
          activeClassName={"active-pagination"}
          />
      
      </div>
         }
    </div>
                </div>
                )
            })
    
        :
        <div>
                 <div>
            <div id='desktop' >
   <Nav/>
    <div>
    <form class="flex items-center justify-center mx-auto" style={{width:"61%"}}>   
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-full">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"/>
            </svg>
        </div>
        <input onChange={e=>setSearch(e.target.value)} type="text" id="simple-search" class="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 px-2.5 py-5  focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Course Title" />
    </div>
    <button onClick={searchFilter} type="submit" style={{backgroundColor:"#000000ff"}} class="p-4 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
        <span class="sr-only">Search</span>
    </button>
</form>
    </div>
    <div className='courseGrid'>

    

{
filteredCourses.slice(pagesVisited,pagesVisited + userPerPage).map(i=>{
const {courseTitle,certificate,courseThumbnail,coursePricing,coursePrice,courseSkills,status,completionTime,courseLevel,totalLectures,courseId,intendedAgeGroup}  = i
return(

<Link to={`/course/${courseId}`} >

<div className="border max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-md  h-full transform hover:scale-105 transition duration-500">
<h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
<div className="relative">
{
courseThumbnail == false ?
<img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>

}

<p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
</div>
<h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
<p style={{fontSize:"11px",marginLeft:'5px',marginBottom:'4px'}} class="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
    {
      certificate == true ?
    <p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
    :
    <p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>
    
    }
       
</div>
</Link>


)
})
}
</div>

{
  filteredCourses.length == 0 &&
  <div className='w-full anton text-center flex flex-col items-center capitalize mb-10 mt-10'>
  We couldn't find any matches for your search. Try refining it
  <img
    onLoad={desktopSpinnerHandler}
    style={{ width: '25%' }}
    src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
    alt='no content'
  />

</div>
}

{
          filteredCourses.length != 0  && 
          <div className='flex justify-center w-full' style={{marginTop:'80px',marginBottom:'80px'}}>
          <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          previousLinkClassName={"previous__link-pagination"}
          nextLinkClassName={"next__link-pagination"}
          containerClassName={"container-pagination flex"}
          disabledClassName={"disabled-pagination"}
          activeClassName={"active-pagination"}
          />
      
      </div>
         }
    
    </div>
    <div id='tablet' >
    <nav className='flex justify-between navTablet w-full  pt-5'>

           
<Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>

<div className='flex justify-end items-center' style={{width:'30%'}}>
<Link to="/courses">

<svg xmlns="http://www.w3.org/2000/svg" style={{width:"25px"}} fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
</Link>

    <svg onClick={mobileNavPop} xmlns="http://www.w3.org/2000/svg" style={{width:"40px"}} fill="currentColor" className="text-blue-800 menuIcon bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg> 
</div>
    </nav>
    {
        pop == true &&
        <div id='popoutTabletHome'>
        <nav className='flex justify-between navTablet py-5'>
        <Link to="/" className='  ml-5 ' style={{width:"15%",objectFit:"cover"}}> 
                     <img onLoad={desktopSpinnerHandler} style={{width:"100%",objectFit:"cover"}} src='https://firebasestorage.googleapis.com/v0/b/dawit-israel.appspot.com/o/kESHT.jpg?alt=media&token=51695213-f893-4279-8b83-ff7647d042c0' alt="logo" /> 
                       </Link>
    
                <div className='flex justify-end'style={{width:'30%'}} >
                    <Link to="/courses">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className=" bi bi-search text-blue-600" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg></Link>
                    
                    <svg onClick={mobileNavPopMinimize} xmlns="http://www.w3.org/2000/svg"  width="25" height="25" fill="currentColor" className="text-blue-800 menuIcon bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                    </div>
        </nav>
        <ul >
            <Link to="/signin">
            <li className='listPopoutTablet border-b border-t' >Sign in</li>
    
             </Link>
             <Link to="/signup/student">
            <li className='listPopoutTablet  border-b' >Sign up</li>
    
    
             </Link>
             <Link to="/aboutus">
            <li className='listPopoutTablet  border-b' >About Us</li>
    
             </Link>
    
             <Link to="/pricing/teacher">
            <li className='listPopoutTablet  border-b' >Pricing</li>
    
             </Link>
    
       
    
        </ul>
      
        
    <Link to='/contact'>
    <button type='submit' style={{backgroundColor:"#000000ff" }} className="navBottomBTN w-full inline-block  text-xl font-medium text-center text-white 
                        rounded-lg transition duration-200 hover:bg-blue-600 ease cursor-pointer">Contact Us</button>
     </Link>   </div>
    }
    <div>
    <form class="flex items-center justify-center mx-auto mb-12" style={{width:"61%"}}>   
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-full">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"/>
            </svg>
        </div>
        <input onChange={e=>setSearch(e.target.value)} style={{fontSize:'11px'}} type="text" id="simple-search" class="borel  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 px-2.5 py-5  focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Course Title" />
    </div>
    <button onClick={searchFilter} type="submit" style={{backgroundColor:"#000000ff"}} class="p-4 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
        <span class="sr-only">Search</span>
    </button>
</form>
    </div>
    <div className='courseGrid mt-8'>

    

{
filteredCourses.slice(pagesVisited,pagesVisited + userPerPage).map(i=>{
const {courseTitle,certificate,courseThumbnail,coursePricing,coursePrice,courseSkills,status,completionTime,courseLevel,totalLectures,courseId,intendedAgeGroup}  = i
return(

<Link to={`/course/${courseId}`} >

<div className="border h-full max-w-sm bg-white px-6 pt-6 pb-5 mb-5 rounded-xl shadow-md transform hover:scale-105 transition duration-500">
<h3 className="mb-3 text-blue-800 anton" style={{fontSize:"25px"}}>{courseLevel} Level</h3>
<div className="relative">
{
courseThumbnail == false ?
<img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

:
<img onLoad={desktopSpinnerHandler} className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'200px',objectFit:'cover'}}/>

}

<p className="absolute top-0 bg-blue-500 text-white font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg fjalla">{coursePrice} ETB</p>
</div>
<h1 className="mt-4 text-gray-800  fjalla mb-4 capitalize" style={{fontSize:"20px"}}>{courseTitle}</h1>
<div className="flex flex-col justify-center">
    
    <p style={{fontSize:"11px",marginLeft:'5px',marginBottom:'4px'}} class="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
    {
      certificate == true ?
    <p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
    :
    <p style={{fontSize:"11px",marginLeft:'5px'}} class="uppercase bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>
    
    }
        
             
      
            </div>
</div>
</Link>


)
})
}
</div>

{
 courses.length != 0 ?
  filteredCourses.length == 0 &&
  <div style={{fontSize:'30px'}} className='px-5 w-full fjalla text-center flex flex-col items-center capitalize mb-10 mt-10'>
  We couldn't find any matches for your search. Try refining it
  <img
    onLoad={desktopSpinnerHandler}
    style={{ width: '25%' }}
    src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
    alt='no content'
  />

</div>
:

<div style={{fontSize:'30px'}} className='px-5 w-full fjalla text-center flex flex-col items-center capitalize mb-10 mt-10'>
We were unable to find courses for you at this time

<img
  onLoad={desktopSpinnerHandler}
  style={{ width: '25%' }}
  src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
  alt='no content'
/>

</div>

}

{
          filteredCourses.length != 0  && 
          <div className='flex justify-center w-full' style={{marginTop:'80px',marginBottom:'80px'}}>
          <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          previousLinkClassName={"previous__link-pagination"}
          nextLinkClassName={"next__link-pagination"}
          containerClassName={"container-pagination flex"}
          disabledClassName={"disabled-pagination"}
          activeClassName={"active-pagination"}
          />
      
      </div>
         }
    
    </div>
    <div id="mobile">
    {
         pop=== false &&
         <div class="navigation-bar__container navigation-bar__container-scrolled navigation-bar__desktop" style={{padding:'0 25px'}}><div class="navigation-bar__left-container">
         <div className="flex w-full justify-between items-center">
         <Link to='/'style={{width:'20%'}}>
        <img style={{width:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/kast%20(1).png?alt=media&token=2f3f067e-1dc7-4bf4-a546-c00c84943ac3" alt="logo" />
        </Link>
           
   
  

           
     
        <div className='flex'>
        {/* <Link to='/signin'>
         <div class="navbar-item__container
       
       header-button-one"><a  class="navbar-item__button border-button hover:bg-[#eeeeee]
           navbar-item__button-default" ><span style={{fontSize:'3vw'}} class="navbar-item__button-text">Log in</span></a></div>
           
           </Link> */}
         <Link to='/signup/student' className='mx-2'>
           
           <div class="navbar-item__container
       
       header-button-two"><a  class="navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'#31ffaa'}}><span style={{fontSize:'2.7vw'}} class="navbar-item__button-text text-gray-900 barlow" >Register as a student</span></a></div>
           </Link>
           <button onClick={mobileNavPop} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-[] text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                      <svg className="w-[6vw] h-[6vw]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                    </button>
        </div>
           </div>
   
              
         
              
 
           
                 
 
                  
             </div>
            
             </div>
        }
   {
    pop == true &&
    <div  style={{left:"0px",backgroundColor:"white",overflow:"hidden",width:'100%',height:'100%',top:'0',zIndex:'100',position:'fixed'}}>
    <div style={{position:'absolute',bottom:'0',left:0}} className='flex justify-center w-full mb-12'>
    
    <Link to='/contact'>
    
    
    <button class=" navbar-item__button border-button-black hover:bg-[#1B1B1B]                    navbar-item__button-outlined" style={{backgroundColor:'white',margin:0}}><span class="navbar-item__button-text text-gray-900 " >Contact Us</span></button>
    </Link>
    </div>
             <ul className='w-1/2 bg-white px-5  rounded-b-xl h-full' style={{width:'100%',background:'#0021db'}}>
    
             <div className='flex justify-between w-full mt-3'>
                   <div>
                   </div>
                   <button onClick={mobileNavPopMinimize} id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 ">
                     <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                   </button>
                 </div>
       
                {
                 <li>
                 <Link to='/aboutus'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">About Us</p> </Link>
             </li>
                 }
                 <li>
                 <Link to='/courses'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Courses</p> </Link>
             </li>
    
    {
                 <li>
                 <Link to='/pricing'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Pricing</p> </Link>
             </li>
                 }
          <li>
                 <Link to='/signin'><p style={{fontSize:'3vw'}} class=" py-1 pr-4 pl-3  text-white  border-gray-100  lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 barlow ">Login</p> </Link>
             </li>
             
             </ul>
         </div>
   }
      
    <form class="flex items-center justify-center mx-auto" style={{width:"70%",marginBottom:'10vw'}}>   
    <label for="simple-search" class="sr-only">Search</label>
    <div class="relative w-full">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"/>
            </svg>
        </div>
        <input onChange={e=>setSearch(e.target.value)} style={{fontSize:'3vw'}} type="text" id="simple-search" class="borel  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 px-2.5 py-5  focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Course Title" />
    </div>
    <button onClick={searchFilter} type="submit" style={{backgroundColor:"#000000ff"}} class="p-4 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
        <span class="sr-only">Search</span>
    </button>
</form>
    <div class="  text-gray-500 flex   mobileGridCourses " style={{overflowX:"scroll", marginLeft:"2vw",marginRight:"2vw"}}>

    {
  filteredCourses.slice(pagesVisited,pagesVisited + userPerPage).map(i=>{
    const {courseTitle,certificate,courseThumbnail,intendedAgeGroup,coursePrice,courseId,courseSkills,completionTime,totalLectures}  = i
    
    return(
      <Link to={`/course/${courseId}`}>
<div class=" border  bg-white rounded-2xl shadow-md relative" style={{backgroundColor:"white",width:'100%',height:'100%',paddingLeft:'2vw',paddingBottom:'10vw',paddingRight:'2vw',paddingTop:'8vw'}}>
        
        <h3 className="mb-3 text-blue-800 anton text-center" style={{fontSize:"5vw"}}>Beginner Friendly</h3>
        <div className="relative">
          {
            courseThumbnail == false ?
    <img  class="h-auto w-full rounded-lg"src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description" style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

:
<img className="w-full rounded-xl" src={courseThumbnail} alt="Thumbnail"  style={{height:'35vw',objectFit:'cover',marginBottom:'5vw'}}/>

          }

          <p className="absolute top-0 bg-blue-500 text-white   rounded-br-lg rounded-tl-lg fjalla" style={{padding:'1.5vw 2vw 1vw 1.5vw ',fontSize:'4vw'}}>{coursePrice} ETB</p>
        </div>
        <h1 className=" text-gray-800  fjalla capitalize" style={{fontSize:"5vw",marginLeft:'3vw',marginBottom:'2vw'}}>{courseTitle}</h1>
        <div className="flex flex-col justify-center">
    
<p style={{fontSize:"3vw",marginLeft:'3vw',marginBottom:'2vw'}} class="uppercase  text-green-600 py-1 px-1 rounded fjalla">For {intendedAgeGroup}</p>
{
  certificate == true ?
<p style={{fontSize:"3vw",marginLeft:'3vw'}} class="uppercase absolute bottom-2 left-2 bg-green-200 text-green-600 py-1 px-1 rounded fjalla text-center">Certificate Available</p>
:
<p style={{fontSize:"3vw",marginLeft:'3vw'}} class="uppercase absolute bottom-2 left-2 bg-red-200 text-red-600 py-1 px-1 rounded fjalla text-center">No Certificate </p>

}
    
         
  
        </div>
      </div>
      </Link>

  
    )
  })
}
    </div>
    {
  filteredCourses.length == 0 &&
  
  <div className='w-full fjalla text-center flex flex-col items-center capitalize ' style={{fontSize:'5vw',padding:'10vw 5vw',lineHeight:'5.5vw'}}>

  {
    search.length !=0 ?
    "We couldn't find any matches for your search. Try refining it" :
    
    "There are no courses available at this time"
  }

<img
    onLoad={desktopSpinnerHandler}
    style={{ width: '50%' }}
    src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Video%20files-bro.svg?alt=media&token=2c309ab3-763a-4cf0-847f-c1b96eb1b857&_gl=1*prfed1*_ga*MTM4NzgzNTAyOC4xNjkwMDA2NTIy*_ga_CW55HF8NVT*MTY5ODU3NDA4MS4xNDguMS4xNjk4NTc1NzM1LjUwLjAuMA..'
    alt='no content'
  />
</div>
}

{
          filteredCourses.length != 0  && 
          <div className='flex justify-center w-full' style={{marginTop:'80px',marginBottom:'80px'}}>
          <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          previousLinkClassName={"previous__link-pagination"}
          nextLinkClassName={"next__link-pagination"}
          containerClassName={"container-pagination flex"}
          disabledClassName={"disabled-pagination"}
          activeClassName={"active-pagination"}
          />
      
      </div>
         }
    </div>
          </div>
    
    
        </div>
    }
</div>
  )
}
